import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export type AddConsultantInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AddConsultantsInput = {
  consultants: Array<AddConsultantInput>;
  surveyId: Scalars['String'];
};

export type AddParticipantHistoryEventInput = {
  data?: InputMaybe<Scalars['JSON']>;
  eventType: ParticipantEventType;
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};

export type AddParticipantInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AddParticipantsInput = {
  clientId: Scalars['String'];
  participants: Array<AddParticipantInput>;
  projectId: Scalars['String'];
  requestorEmails?: InputMaybe<Array<Scalars['String']>>;
  surveyId: Scalars['String'];
};

export type Archetype = {
  __typename?: 'Archetype';
  behaviours?: Maybe<Array<Behaviour>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  nameTexts?: Maybe<Array<LanguageText>>;
  priority: Scalars['Float'];
};

export type ArchetypeDataItem = {
  __typename?: 'ArchetypeDataItem';
  archetypeId: Scalars['Float'];
  color: Scalars['String'];
  fontColor?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  priority: Scalars['Float'];
  value: Scalars['Float'];
};

export type ArchetypeItem = {
  __typename?: 'ArchetypeItem';
  archetypeId: Scalars['Float'];
  dataList: Array<DataItem>;
  title: Scalars['String'];
};

export type ArchetypeScoreText = {
  __typename?: 'ArchetypeScoreText';
  archetypeId: Scalars['Float'];
  behaviourId: Scalars['Float'];
  id: Scalars['ID'];
  scoreTexts: Array<LanguageText>;
  scoreThreshold: Scalars['Float'];
  survey: Survey;
  surveyId: Scalars['String'];
};

export type AssignClientsInput = {
  clientIds: Array<Scalars['String']>;
  userId: Scalars['Float'];
};

export type AssignParticipantsInput = {
  participantIds: Array<Scalars['String']>;
  userId: Scalars['Float'];
};

export type AssignProjectsInput = {
  projectIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type Behaviour = {
  __typename?: 'Behaviour';
  archetypeId: Scalars['Float'];
  id: Scalars['Int'];
  interviewQuestionTexts?: Maybe<Array<LanguageTexts>>;
  interviewQuestions?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  nameTexts?: Maybe<Array<LanguageText>>;
  priority: Scalars['Float'];
};

export type CsvUsersError = {
  __typename?: 'CSVUsersError';
  message: Scalars['String'];
  row: Scalars['Float'];
};

export type CsvUsersResult = {
  __typename?: 'CSVUsersResult';
  created: Scalars['Float'];
  expectedTotalRows: Scalars['Float'];
  updated: Scalars['Float'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Float'];
  questionGroups: Array<QuestionGroup>;
  title: Scalars['String'];
};

export type CategoryInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Float'];
  questionGroups: Array<QuestionGroupInput>;
  title: Scalars['String'];
};

export type ChangeEmailConfigInput = {
  clientId: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type Client = {
  __typename?: 'Client';
  behaviours?: Maybe<Array<Behaviour>>;
  createdAt: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  emailConfig: Array<ClientEmailConfig>;
  groupReports?: Maybe<Array<GroupReport>>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isConfigEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  primaryArchetypeId?: Maybe<Scalars['Float']>;
  projects?: Maybe<Array<Project>>;
  secondaryArchetypeId?: Maybe<Scalars['Float']>;
  teamReports?: Maybe<Array<TeamReport>>;
  updatedAt: Scalars['DateTime'];
  userClients?: Maybe<Array<UserClients>>;
};

export type ClientEmailConfig = {
  __typename?: 'ClientEmailConfig';
  body: Scalars['String'];
  linkText?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  type: EmailType;
};

export type ClientForParticipantInput = {
  clientId: Scalars['String'];
  participantId: Scalars['String'];
};

export type ClientModel = {
  __typename?: 'ClientModel';
  behaviours?: Maybe<Array<Behaviour>>;
  createdAt: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  emailConfig: Array<ClientEmailConfig>;
  groupReports?: Maybe<Array<GroupReport>>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isConfigEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  primaryArchetypeId?: Maybe<Scalars['Float']>;
  projects?: Maybe<Array<Project>>;
  secondaryArchetypeId?: Maybe<Scalars['Float']>;
  teamReports?: Maybe<Array<TeamReport>>;
  updatedAt: Scalars['DateTime'];
  userClients?: Maybe<Array<UserClients>>;
};

export type ClientResponse = {
  __typename?: 'ClientResponse';
  client?: Maybe<ClientModel>;
  error?: Maybe<Scalars['String']>;
};

export type CompleteParticipantResponse = {
  __typename?: 'CompleteParticipantResponse';
  message: Scalars['String'];
};

export type CopyOrMoveParticipantsInput = {
  clientId: Scalars['String'];
  moveProjectId?: InputMaybe<Scalars['String']>;
  participantIds: Array<Scalars['String']>;
  projectId: Scalars['String'];
};

export type CreateClientInput = {
  name: Scalars['String'];
};

export type CreateDemographicInput = {
  expectedValues?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type CreateGroupReportInput = {
  clientId: Scalars['String'];
  participantIds: Array<Scalars['String']>;
  projectId: Scalars['String'];
  reportName: Scalars['String'];
  surveyId: Scalars['String'];
};

export type CreateProjectInput = {
  clientId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateSurveyAnswerInput = {
  participantId: Scalars['String'];
  response: ResponseInput;
  surveyId: Scalars['String'];
};

export type CreateSurveyInput = {
  adminUserEmailAddresses?: InputMaybe<Array<Scalars['String']>>;
  availableLanguages?: InputMaybe<Array<LanguageCodeEnum>>;
  clientId: Scalars['String'];
  defaultLanguage?: InputMaybe<LanguageCodeEnum>;
  demographicsRequired?: InputMaybe<Array<CreateDemographicInput>>;
  endDate: Scalars['JSON'];
  invite?: InputMaybe<SurveyEmailInput>;
  launchDate: Scalars['JSON'];
  reminder?: InputMaybe<SurveyEmailInput>;
  surveyAdminEmailAddresses?: InputMaybe<Array<Scalars['String']>>;
  surveySchemaId?: InputMaybe<Scalars['String']>;
  surveyViewerEmailAddresses?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type CreateSurveySchemaInput = {
  emailConfig: Array<EmailConfigInput>;
  schema: SurveySchemaModelInput;
  title: Scalars['String'];
  version: Scalars['Float'];
};

export type CreateTeamReportInput = {
  clientId: Scalars['String'];
  isTargetCulture: Scalars['Boolean'];
  participantIds: Array<Scalars['String']>;
  projectId: Scalars['String'];
  reportName: Scalars['String'];
  surveyId: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  organisationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  role?: UserRole;
  surveyIds: Array<Scalars['String']>;
};

export type DataItem = {
  __typename?: 'DataItem';
  color: Scalars['String'];
  fontColor?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  shouldHighlightSection: Scalars['Boolean'];
  value: Scalars['Float'];
};

export type DeleteClientInput = {
  id: Scalars['String'];
};

export type DeleteConsultantInput = {
  id: Scalars['String'];
};

export type DeleteProjectInput = {
  id: Scalars['String'];
};

export type DeleteSurveyInput = {
  id: Scalars['String'];
};

export type Demographic = {
  __typename?: 'Demographic';
  expectedValues?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type DemographicResponse = {
  __typename?: 'DemographicResponse';
  id: Scalars['String'];
  title: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type DemographicResult = {
  __typename?: 'DemographicResult';
  distinctValues: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type EmailConfigInput = {
  templates: Array<EmailTemplateInput>;
  type: EmailType;
};

export type EmailConfigModel = {
  __typename?: 'EmailConfigModel';
  templates: Array<EmailTemplate>;
  type: EmailType;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  body: Scalars['String'];
  languageCode: LanguageCodeEnum;
  linkText?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
};

export type EmailTemplateInput = {
  body: Scalars['String'];
  languageCode: LanguageCodeEnum;
  linkText: Scalars['String'];
  subject: Scalars['String'];
};

/** Email Type */
export enum EmailType {
  InviteComplete = 'INVITE_COMPLETE',
  ReminderComplete = 'REMINDER_COMPLETE'
}

/** Gender */
export enum GenderEnum {
  Female = 'female',
  Male = 'male',
  NonBinary = 'nonBinary',
  Unknown = 'unknown'
}

export type GetAdminProjectsInput = {
  userId: Scalars['String'];
};

export type GetClientsQueryInput = {
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type GetDuplicateParticipants = {
  participantEmails: Array<Scalars['String']>;
};

export type GetGroupReportsInput = {
  clientId: Scalars['String'];
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  projectId: Scalars['String'];
  sort?: InputMaybe<SortInput>;
  surveyId: Scalars['String'];
};

export type GetPagedParticipantInput = {
  clientId?: InputMaybe<Scalars['String']>;
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  projectId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortInput>;
  surveyId: Scalars['String'];
};

export type GetParticipant = {
  __typename?: 'GetParticipant';
  participant: Participant;
  survey: SurveyWithQuestionOrder;
};

export type GetProjectsInput = {
  clientId?: InputMaybe<Scalars['String']>;
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortInput>;
};

export type GetSurveyQueryInput = {
  languageCode: Scalars['String'];
  surveyId: Scalars['String'];
};

export type GetSurveysByClientQueryInput = {
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type GetTeamReportsInput = {
  clientId: Scalars['String'];
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  projectId: Scalars['String'];
  sort?: InputMaybe<SortInput>;
  surveyId: Scalars['String'];
};

export type GetUserByTokenInput = {
  token: Scalars['String'];
};

export type GetUsersQueryInput = {
  email?: InputMaybe<Scalars['String']>;
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type GroupArchetypePreferences = {
  __typename?: 'GroupArchetypePreferences';
  dataList: Array<ArchetypeDataItem>;
  participantName: Scalars['String'];
};

export type GroupArchetypePreferencesInput = {
  groupId: Scalars['String'];
  languageCode: LanguageCodeEnum;
  surveyId: Scalars['String'];
};

export type GroupBehaviorPreferences = {
  __typename?: 'GroupBehaviorPreferences';
  archetypeItems: Array<ArchetypeItem>;
  participantId: Scalars['String'];
  participantName: Scalars['String'];
};

export type GroupBehaviorPreferencesInput = {
  groupId: Scalars['String'];
  languageCode: LanguageCodeEnum;
  surveyId: Scalars['String'];
};

export type GroupBehaviorProfileInput = {
  groupId: Scalars['String'];
  languageCode: LanguageCodeEnum;
  surveyId: Scalars['String'];
};

export type GroupReport = {
  __typename?: 'GroupReport';
  behaviours?: Maybe<Array<Behaviour>>;
  client: Client;
  clientId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  groupReportParticipants?: Maybe<Array<GroupReportParticipant>>;
  groupReportPdfs?: Maybe<Array<GroupReportParticipant>>;
  id: Scalars['String'];
  primaryArchetypeId: Scalars['Float'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['String']>;
  reportName: Scalars['String'];
  secondaryArchetypeId?: Maybe<Scalars['Float']>;
  surveyId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type GroupReportParticipant = {
  __typename?: 'GroupReportParticipant';
  groupReportId: Scalars['String'];
  id: Scalars['String'];
  language: LanguageCodeEnum;
  participant: Participant;
  participantId: Scalars['String'];
  reportPdfGcsName?: Maybe<Scalars['String']>;
};

export type GroupReportPdf = {
  __typename?: 'GroupReportPdf';
  createdAt: Scalars['DateTime'];
  groupReport: GroupReport;
  groupReportId: Scalars['String'];
  id: Scalars['String'];
  language: LanguageCodeEnum;
  reportPdfGcsName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type HexagonalDiagramData = {
  __typename?: 'HexagonalDiagramData';
  archetypeId: Scalars['Float'];
  behaviorId: Scalars['Float'];
  color?: Maybe<Scalars['String']>;
  fontBold?: Maybe<Scalars['Boolean']>;
  fontColor: Scalars['String'];
  label: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  shouldHighlightSection?: Maybe<Scalars['Boolean']>;
  showLine?: Maybe<Scalars['Boolean']>;
  value: Scalars['Float'];
};

export type ImportCsvUsersResponse = {
  __typename?: 'ImportCSVUsersResponse';
  demographics?: Maybe<Array<DemographicResult>>;
  errors?: Maybe<Array<CsvUsersError>>;
  importId: Scalars['String'];
  results?: Maybe<CsvUsersResult>;
  rows: Scalars['Float'];
  status: Scalars['String'];
};

export type ImportData = {
  __typename?: 'ImportData';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ImportDataInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ImportParticipantInput = {
  parsedRecords: Array<ParsedRecordInput>;
};

export type IndividualReport = {
  __typename?: 'IndividualReport';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isTargetCulture: Scalars['Boolean'];
  language: LanguageCodeEnum;
  participant: Participant;
  participantId: Scalars['String'];
  reportPdfGcsName: Scalars['String'];
  surveyId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type InviteUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles: Array<UserRole>;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['Float'];
  label: Scalars['String'];
};

/** Language Code */
export enum LanguageCodeEnum {
  Da = 'da',
  De = 'de',
  EnGb = 'en_gb',
  EnUs = 'en_us',
  Es = 'es',
  Fr = 'fr',
  Hi = 'hi',
  It = 'it',
  Nl = 'nl',
  Pl = 'pl',
  Pt = 'pt',
  Ro = 'ro',
  Sv = 'sv'
}

export type LanguageText = {
  __typename?: 'LanguageText';
  languageCode: LanguageCodeEnum;
  text: Scalars['String'];
};

export type LanguageTexts = {
  __typename?: 'LanguageTexts';
  languageCode: LanguageCodeEnum;
  texts?: Maybe<Array<Scalars['String']>>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AssignParticipants: Scalars['Boolean'];
  addConsultants: Array<User>;
  addParticipantEventHistory: ParticipantEventHistory;
  addParticipants: Scalars['Boolean'];
  addSurveyHistoryEvent: Survey;
  assignClients: Scalars['Boolean'];
  assignClientsAndProjects: Scalars['Boolean'];
  assignProjects: Scalars['Boolean'];
  changeEmailConfig: Scalars['Boolean'];
  completeSurvey: CompleteParticipantResponse;
  copyOrMoveParticipants: Scalars['Boolean'];
  createAdminUser: User;
  createClient: ClientResponse;
  createGroupReport: GroupReport;
  createProject: Project;
  createProjectsForAllClients: Scalars['Boolean'];
  createResponseReport: Scalars['String'];
  createSurvey: Survey;
  createSurveySchema: SurveySchema;
  createTeamReport: TeamReport;
  createTeamReportPdf: TeamReportPdf;
  createUser: UserResponse;
  deleteClient: Client;
  deleteConsultant: Scalars['Boolean'];
  deleteDeviceToken?: Maybe<Scalars['Boolean']>;
  deleteGroupReport: Scalars['Boolean'];
  deleteParticipant: Scalars['Boolean'];
  deleteProject: Scalars['Boolean'];
  deleteSurvey: Survey;
  deleteSurveySchema: SurveySchema;
  deleteSurveys: Scalars['Boolean'];
  deleteTeamReport: Scalars['Boolean'];
  editUser: UserResponse;
  forgotPassword: Scalars['Boolean'];
  generateTestPuppeteerPdf: Scalars['Boolean'];
  importParticipants: ImportCsvUsersResponse;
  inviteUser: User;
  login: UserResponse;
  register: UserResponse;
  resetPassword: Scalars['Boolean'];
  resetSurveyEmailTemplate: EmailTemplate;
  restoreParticipant: Scalars['Boolean'];
  saveAnswer: Participant;
  saveClientForParticipant: Participant;
  saveDemographics: Participant;
  saveDeviceToken?: Maybe<Scalars['Boolean']>;
  saveGroupReportParticipant: GroupReportParticipant;
  saveGroupReportPdf: GroupReportPdf;
  saveServiceTypeForParticipant: Participant;
  saveTargetCultureClient: Client;
  seedAdminUser: Scalars['Boolean'];
  seedArchetypesAndBehaviours: Scalars['Boolean'];
  seedSurvey: Scalars['Boolean'];
  seedSurveySchemas: Scalars['Boolean'];
  sendInviteNotificationToParticipant: Scalars['Boolean'];
  sendInviteNotifications: Scalars['Boolean'];
  sendReminderToParticipant: Scalars['Boolean'];
  setPassword: UserResponse;
  setPasswordForInvitedUser: Scalars['Boolean'];
  updateClient: Client;
  updateClientEmailConfig: Client;
  updateProfile: User;
  updateProjectName: Project;
  updateSurvey: Survey;
  updateSurveyEmail: SurveyEmailResponse;
  updateSurveySchema: SurveySchema;
  updateUserRole: User;
};


export type MutationAssignParticipantsArgs = {
  options: AssignParticipantsInput;
};


export type MutationAddConsultantsArgs = {
  options: AddConsultantsInput;
};


export type MutationAddParticipantEventHistoryArgs = {
  options: AddParticipantHistoryEventInput;
};


export type MutationAddParticipantsArgs = {
  options: AddParticipantsInput;
};


export type MutationAddSurveyHistoryEventArgs = {
  surveyEventType: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationAssignClientsArgs = {
  options: AssignClientsInput;
};


export type MutationAssignClientsAndProjectsArgs = {
  options: AssignClientsInput;
};


export type MutationAssignProjectsArgs = {
  options: AssignProjectsInput;
};


export type MutationChangeEmailConfigArgs = {
  options: ChangeEmailConfigInput;
};


export type MutationCompleteSurveyArgs = {
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationCopyOrMoveParticipantsArgs = {
  options: CopyOrMoveParticipantsInput;
};


export type MutationCreateAdminUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateClientArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  options: CreateClientInput;
};


export type MutationCreateGroupReportArgs = {
  options: CreateGroupReportInput;
};


export type MutationCreateProjectArgs = {
  options: CreateProjectInput;
};


export type MutationCreateResponseReportArgs = {
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationCreateSurveyArgs = {
  options: CreateSurveyInput;
};


export type MutationCreateSurveySchemaArgs = {
  options: CreateSurveySchemaInput;
};


export type MutationCreateTeamReportArgs = {
  options: CreateTeamReportInput;
};


export type MutationCreateTeamReportPdfArgs = {
  pdfLanguage: Scalars['String'];
  surveyId: Scalars['String'];
  teamReportId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  userOptions: CreateUserInput;
};


export type MutationDeleteClientArgs = {
  options: DeleteClientInput;
};


export type MutationDeleteConsultantArgs = {
  options: DeleteConsultantInput;
};


export type MutationDeleteDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationDeleteGroupReportArgs = {
  groupReportId: Scalars['String'];
};


export type MutationDeleteParticipantArgs = {
  participantId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationDeleteProjectArgs = {
  options: DeleteProjectInput;
};


export type MutationDeleteSurveyArgs = {
  options: DeleteSurveyInput;
};


export type MutationDeleteSurveySchemaArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTeamReportArgs = {
  teamReportId: Scalars['String'];
};


export type MutationEditUserArgs = {
  id: Scalars['Float'];
  userOptions: UpdateUserInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGenerateTestPuppeteerPdfArgs = {
  url: Scalars['String'];
};


export type MutationImportParticipantsArgs = {
  clientId: Scalars['String'];
  input: ImportParticipantInput;
  projectId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationInviteUserArgs = {
  options: InviteUserInput;
};


export type MutationLoginArgs = {
  options: LoginInput;
};


export type MutationRegisterArgs = {
  options: RegisterInput;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetSurveyEmailTemplateArgs = {
  emailType: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationRestoreParticipantArgs = {
  participantId: Scalars['String'];
};


export type MutationSaveAnswerArgs = {
  options: CreateSurveyAnswerInput;
};


export type MutationSaveClientForParticipantArgs = {
  options: ClientForParticipantInput;
};


export type MutationSaveDemographicsArgs = {
  options: UpdateParticipantDemographicInput;
};


export type MutationSaveDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationSaveGroupReportParticipantArgs = {
  groupId: Scalars['String'];
  participantId: Scalars['String'];
  pdfLanguage: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationSaveGroupReportPdfArgs = {
  groupId: Scalars['String'];
  pdfLanguage: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationSaveServiceTypeForParticipantArgs = {
  options: ServiceTypeInput;
};


export type MutationSaveTargetCultureClientArgs = {
  options: TargetCultureInput;
};


export type MutationSendInviteNotificationToParticipantArgs = {
  clientId: Scalars['String'];
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationSendInviteNotificationsArgs = {
  importId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationSendReminderToParticipantArgs = {
  clientId: Scalars['String'];
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationSetPasswordArgs = {
  options: SetPasswordInput;
};


export type MutationSetPasswordForInvitedUserArgs = {
  options: SetPasswordInput;
};


export type MutationUpdateClientArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  options: UpdateClientInput;
};


export type MutationUpdateClientEmailConfigArgs = {
  options: UpdateClientConfigInput;
};


export type MutationUpdateProfileArgs = {
  options: UpdateProfileInput;
};


export type MutationUpdateProjectNameArgs = {
  options: UpdateProjectInput;
};


export type MutationUpdateSurveyArgs = {
  options: UpdateSurveyInput;
};


export type MutationUpdateSurveyEmailArgs = {
  data: SurveyEmailInput;
  emailType: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationUpdateSurveySchemaArgs = {
  options: UpdateSurveySchemaInput;
};


export type MutationUpdateUserRoleArgs = {
  options: UpdateUserRoleInput;
};

export type Options = {
  __typename?: 'Options';
  behaviourId?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  order: Scalars['Float'];
  relatedId?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type OptionsInput = {
  behaviourId: Scalars['Float'];
  id: Scalars['String'];
  order: Scalars['Float'];
  relatedId?: InputMaybe<Scalars['String']>;
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type PagedClientsResponse = {
  __typename?: 'PagedClientsResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<ClientModel>;
  totalCount: Scalars['Float'];
};

export type PagedGroupReports = {
  __typename?: 'PagedGroupReports';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<GroupReport>;
  totalCount: Scalars['Float'];
};

export type PagedParticipantInput = {
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type PagedParticipants = {
  __typename?: 'PagedParticipants';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<Participant>;
  totalCount: Scalars['Float'];
};

export type PagedProjects = {
  __typename?: 'PagedProjects';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<Project>;
  totalCount: Scalars['Float'];
};

export type PagedQueryInput = {
  pageOffset?: InputMaybe<Scalars['Float']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type PagedSurveysResponse = {
  __typename?: 'PagedSurveysResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<Survey>;
  totalCount: Scalars['Float'];
};

export type PagedTeamReports = {
  __typename?: 'PagedTeamReports';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<TeamReport>;
  totalCount: Scalars['Float'];
};

export type PagedUsers = {
  __typename?: 'PagedUsers';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<User>;
  totalCount: Scalars['Float'];
};

export type PagedUsersResponse = {
  __typename?: 'PagedUsersResponse';
  pageOffset: Scalars['Float'];
  pageSize: Scalars['Float'];
  results: Array<UserModel>;
  totalCount: Scalars['Float'];
};

export type ParseCsvUsersResponse = {
  __typename?: 'ParseCSVUsersResponse';
  errors?: Maybe<Array<CsvUsersError>>;
  parsedRecords?: Maybe<Array<ParsedRecord>>;
  status: Scalars['String'];
};

export type ParsedRecord = {
  __typename?: 'ParsedRecord';
  data?: Maybe<Array<ImportData>>;
};

export type ParsedRecordInput = {
  data?: InputMaybe<Array<ImportDataInput>>;
};

export type Participant = {
  __typename?: 'Participant';
  clients?: Maybe<Array<Client>>;
  consent: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['DateTime']>;
  demographics: Array<DemographicResponse>;
  groupReportParticipants?: Maybe<Array<GroupReportParticipant>>;
  id: Scalars['ID'];
  individualReports: Array<IndividualReport>;
  manager: User;
  managerUserId: Scalars['String'];
  participantEventHistory?: Maybe<Array<ParticipantEventHistory>>;
  projects: Array<Project>;
  reportings?: Maybe<Array<Reporting>>;
  requestorEmails?: Maybe<Array<Scalars['String']>>;
  responses: Array<Response>;
  serviceTypes: Array<ServiceType>;
  survey: Survey;
  surveyId: Scalars['String'];
  surveyProgress?: Maybe<Scalars['Float']>;
  surveyStatus?: Maybe<ParticipantEventType>;
  tags: Array<Tag>;
  testAccount: Scalars['Boolean'];
  user: User;
  userId: Scalars['String'];
  userParticipants?: Maybe<Array<UserParticipants>>;
};

export type ParticipantEventHistory = {
  __typename?: 'ParticipantEventHistory';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  type: ParticipantEventType;
};

/** The events that can occur on a survey for a participant */
export enum ParticipantEventType {
  InvitationGenerated = 'INVITATION_GENERATED',
  InvitationSent = 'INVITATION_SENT',
  ParticipantUploaded = 'PARTICIPANT_UPLOADED',
  ReminderGenerated = 'REMINDER_GENERATED',
  ReminderSent = 'REMINDER_SENT',
  SurveyComplete = 'SURVEY_COMPLETE',
  SurveyInstructionRead = 'SURVEY_INSTRUCTION_READ',
  SurveyQuestionAnswered = 'SURVEY_QUESTION_ANSWERED',
  SurveyReadyToComplete = 'SURVEY_READY_TO_COMPLETE',
  SurveyStarted = 'SURVEY_STARTED'
}

export type Project = {
  __typename?: 'Project';
  client: Client;
  clientId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  groupReports: Array<GroupReport>;
  id: Scalars['String'];
  name: Scalars['String'];
  participantCompletedCount: Scalars['Float'];
  participantCount: Scalars['Float'];
  participants: Array<Participant>;
  teamReports: Array<TeamReport>;
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};

export type Query = {
  __typename?: 'Query';
  client: QueryClientResponse;
  clientForParticipant: QueryParticipantClientResponse;
  clients: PagedClientsResponse;
  consultants: PagedUsers;
  getAdminProjects: Array<UserProjectPermission>;
  getAllParticipantDataCsv: Scalars['String'];
  getArchetypes: Array<Archetype>;
  getArchetypesWithToken: Array<Archetype>;
  getClientProject: Project;
  getClientProjects: PagedProjects;
  getCtaUrlForUser: Scalars['String'];
  getDuplicateParticipants: Array<Participant>;
  getGroupParticipantReport: GroupReportParticipant;
  getGroupReport: GroupReport;
  getGroupReportPdf: GroupReportPdf;
  getGroupReportWithToken: GroupReport;
  getGroupReports: PagedGroupReports;
  getIndividualReportPdf: IndividualReport;
  getReportPdfDownloadUrl: Scalars['String'];
  getSurveyEmailTemplate: SurveyEmailResponse;
  getSurveyProgress: SurveyProgressResponse;
  getTeamReport: TeamReport;
  getTeamReportPdf: TeamReportPdf;
  getTeamReportWithToken: TeamReport;
  getTeamReports: PagedTeamReports;
  getUserByToken: User;
  groupArchetypePreferences: Array<GroupArchetypePreferences>;
  groupBehaviorPreferences: Array<GroupBehaviorPreferences>;
  groupBehaviorProfile: Array<HexagonalDiagramData>;
  isUserEmailVerified: Scalars['Boolean'];
  items: Array<Item>;
  parseCSVUsers: ParseCsvUsersResponse;
  participant: Participant;
  participantEventHistory: Array<ParticipantEventHistory>;
  participantSurveys: PagedParticipants;
  participantWithEventHistory: Participant;
  participantWithSurvey: GetParticipant;
  participantWithToken: Participant;
  participants: PagedParticipants;
  participantsLightWeight: PagedParticipants;
  reportingForGroup: Array<Reporting>;
  reportingForGroupWithToken: Array<Reporting>;
  reportingForParticipant: Array<Reporting>;
  reportingForParticipantWithToken: Array<Reporting>;
  reportingForTeam: Array<Reporting>;
  reportingForTeamWithToken: Array<Reporting>;
  secretItems: Array<SecretItem>;
  self: User;
  survey?: Maybe<Survey>;
  surveySchema: SurveySchema;
  surveyWithToken?: Maybe<Survey>;
  surveys: PagedSurveysResponse;
  suveySchemas: Array<SurveySchema>;
  user: QueryUserResponse;
  users: PagedUsersResponse;
};


export type QueryClientArgs = {
  options: QueryClientInput;
};


export type QueryClientForParticipantArgs = {
  options: QueryClientInput;
};


export type QueryClientsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<GetClientsQueryInput>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SortInput>;
};


export type QueryConsultantsArgs = {
  options?: InputMaybe<PagedQueryInput>;
};


export type QueryGetAdminProjectsArgs = {
  options: GetAdminProjectsInput;
};


export type QueryGetAllParticipantDataCsvArgs = {
  surveyId: Scalars['String'];
};


export type QueryGetArchetypesWithTokenArgs = {
  token: Scalars['String'];
};


export type QueryGetClientProjectArgs = {
  id: Scalars['String'];
};


export type QueryGetClientProjectsArgs = {
  options: GetProjectsInput;
};


export type QueryGetCtaUrlForUserArgs = {
  email: Scalars['String'];
};


export type QueryGetDuplicateParticipantsArgs = {
  options: GetDuplicateParticipants;
};


export type QueryGetGroupParticipantReportArgs = {
  groupId: Scalars['String'];
  participantId: Scalars['String'];
  pdfLanguage: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryGetGroupReportArgs = {
  groupId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryGetGroupReportPdfArgs = {
  groupId: Scalars['String'];
  pdfLanguage: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryGetGroupReportWithTokenArgs = {
  groupId: Scalars['String'];
  participantId?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryGetGroupReportsArgs = {
  options?: InputMaybe<GetGroupReportsInput>;
};


export type QueryGetIndividualReportPdfArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  isTargetCulture: Scalars['Boolean'];
  participantId: Scalars['String'];
  pdfLanguage: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryGetReportPdfDownloadUrlArgs = {
  reportPdfGcsName: Scalars['String'];
};


export type QueryGetSurveyEmailTemplateArgs = {
  emailType: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryGetSurveyProgressArgs = {
  surveyId: Scalars['String'];
};


export type QueryGetTeamReportArgs = {
  surveyId: Scalars['String'];
  teamReportId: Scalars['String'];
};


export type QueryGetTeamReportPdfArgs = {
  isTargetCulture?: InputMaybe<Scalars['Boolean']>;
  pdfLanguage: Scalars['String'];
  surveyId: Scalars['String'];
  teamReportId: Scalars['String'];
};


export type QueryGetTeamReportWithTokenArgs = {
  participantId?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['String'];
  teamReportId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryGetTeamReportsArgs = {
  options?: InputMaybe<GetTeamReportsInput>;
};


export type QueryGetUserByTokenArgs = {
  options: GetUserByTokenInput;
};


export type QueryGroupArchetypePreferencesArgs = {
  options: GroupArchetypePreferencesInput;
};


export type QueryGroupBehaviorPreferencesArgs = {
  options: GroupBehaviorPreferencesInput;
};


export type QueryGroupBehaviorProfileArgs = {
  options: GroupBehaviorProfileInput;
};


export type QueryIsUserEmailVerifiedArgs = {
  email: Scalars['String'];
};


export type QueryParseCsvUsersArgs = {
  file: Scalars['Upload'];
};


export type QueryParticipantArgs = {
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryParticipantEventHistoryArgs = {
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryParticipantSurveysArgs = {
  options?: InputMaybe<PagedParticipantInput>;
};


export type QueryParticipantWithEventHistoryArgs = {
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryParticipantWithSurveyArgs = {
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryParticipantWithTokenArgs = {
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryParticipantsArgs = {
  options?: InputMaybe<GetPagedParticipantInput>;
};


export type QueryParticipantsLightWeightArgs = {
  options?: InputMaybe<GetPagedParticipantInput>;
};


export type QueryReportingForGroupArgs = {
  groupId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryReportingForGroupWithTokenArgs = {
  groupId: Scalars['String'];
  languageCode?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryReportingForParticipantArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryReportingForParticipantWithTokenArgs = {
  languageCode?: InputMaybe<Scalars['String']>;
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryReportingForTeamArgs = {
  surveyId: Scalars['String'];
  teamReportId: Scalars['String'];
};


export type QueryReportingForTeamWithTokenArgs = {
  surveyId: Scalars['String'];
  teamReportId: Scalars['String'];
  token: Scalars['String'];
};


export type QuerySurveyArgs = {
  options: GetSurveyQueryInput;
};


export type QuerySurveySchemaArgs = {
  options: QuerySurveySchemaInput;
};


export type QuerySurveyWithTokenArgs = {
  options: GetSurveyQueryInput;
  token: Scalars['String'];
};


export type QuerySurveysArgs = {
  options?: InputMaybe<GetSurveysByClientQueryInput>;
};


export type QueryUserArgs = {
  options: QueryUserInput;
};


export type QueryUsersArgs = {
  options?: InputMaybe<GetUsersQueryInput>;
};

export type QueryClientInput = {
  id: Scalars['ID'];
};

export type QueryClientResponse = {
  __typename?: 'QueryClientResponse';
  client?: Maybe<ClientModel>;
  error?: Maybe<Scalars['String']>;
};

export type QueryParticipantClientResponse = {
  __typename?: 'QueryParticipantClientResponse';
  error?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type QuerySurveySchemaInput = {
  id: Scalars['ID'];
};

export type QueryUserInput = {
  id: Scalars['ID'];
};

export type QueryUserResponse = {
  __typename?: 'QueryUserResponse';
  error?: Maybe<Scalars['String']>;
  user?: Maybe<UserModel>;
};

export type Question = {
  __typename?: 'Question';
  defaultValueIfUnanswered?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOptional?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Options>>;
  order: Scalars['Float'];
  questionText: Scalars['String'];
  type: QuestionType;
};

export type QuestionGroup = {
  __typename?: 'QuestionGroup';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Float'];
  questions: Array<Question>;
  title: Scalars['String'];
};

export type QuestionGroupInput = {
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Float'];
  questions: Array<QuestionInput>;
  title: Scalars['String'];
};

export type QuestionInput = {
  defaultValueIfUnanswered?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isOptional?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Array<OptionsInput>>;
  order: Scalars['Float'];
  questionText: Scalars['String'];
  type: QuestionType;
};

export type QuestionOrder = {
  __typename?: 'QuestionOrder';
  categoryIndex?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  index: Scalars['Float'];
  orderIndex?: Maybe<Scalars['Float']>;
  questionGroupIndex?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
};

/** Question Type */
export enum QuestionType {
  AgreeDisagree_7 = 'AGREE_DISAGREE_7',
  Boolean = 'BOOLEAN',
  Check = 'CHECK',
  MultiSelect = 'MULTI_SELECT',
  NumberSelect = 'NUMBER_SELECT',
  PriorityOrder = 'PRIORITY_ORDER',
  TextInput = 'TEXT_INPUT'
}

export type RankedAnswer = {
  __typename?: 'RankedAnswer';
  optionId: Scalars['String'];
  order: Scalars['Float'];
};

export type RankedAnswerInput = {
  optionId: Scalars['String'];
  order: Scalars['Float'];
};

export type RegisterInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  organisationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type ReminderInput = {
  id?: InputMaybe<Scalars['Float']>;
  time: Scalars['Float'];
  timePeriod: Scalars['Float'];
  timezoneName: Scalars['String'];
};

export type Reporting = {
  __typename?: 'Reporting';
  archetypeId: Scalars['Float'];
  behaviourId: Scalars['Float'];
  id: Scalars['ID'];
  participant?: Maybe<Participant>;
  participantId: Scalars['String'];
  score: Scalars['Float'];
  scoreText: Scalars['String'];
  surveyId: Scalars['String'];
};

export type Response = {
  __typename?: 'Response';
  answer?: Maybe<Array<RankedAnswer>>;
  categoryId: Scalars['String'];
  questionGroupId: Scalars['String'];
  questionId: Scalars['String'];
};

export type ResponseInput = {
  answer: Array<RankedAnswerInput>;
  categoryId: Scalars['String'];
  questionGroupId: Scalars['String'];
  questionId: Scalars['String'];
};

export type SecretItem = {
  __typename?: 'SecretItem';
  description: Scalars['String'];
  id: Scalars['Float'];
  label: Scalars['String'];
};

export type ServiceType = {
  __typename?: 'ServiceType';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ServiceTypeInput = {
  participantId: Scalars['String'];
  serviceType: Type;
};

export type SetPasswordInput = {
  consent?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type SortInput = {
  direction: Scalars['String'];
  id: Scalars['String'];
};

export type Survey = {
  __typename?: 'Survey';
  archetypeScoreText?: Maybe<Array<ArchetypeScoreText>>;
  deleted: Scalars['Boolean'];
  demographicsRequired: Array<Demographic>;
  id: Scalars['ID'];
  participants?: Maybe<Array<Participant>>;
  surveyEventHistory?: Maybe<Array<SurveyEventHistory>>;
  surveyReminders: Array<SurveyReminders>;
  surveySchema: SurveySchema;
  surveySchemaId: Scalars['String'];
  title: Scalars['String'];
};

export type SurveyEmailInput = {
  reminders?: InputMaybe<Array<ReminderInput>>;
  template: EmailTemplateInput;
};

export type SurveyEmailResponse = {
  __typename?: 'SurveyEmailResponse';
  reminders?: Maybe<Array<SurveyReminders>>;
  template: EmailTemplate;
};

export type SurveyEventHistory = {
  __typename?: 'SurveyEventHistory';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  survey: Survey;
  surveyId: Scalars['ID'];
  type: SurveyEventType;
};

/** The events that can occur on a survey */
export enum SurveyEventType {
  InvitationEmailsFinished = 'INVITATION_EMAILS_FINISHED',
  InvitationEmailsProgress = 'INVITATION_EMAILS_PROGRESS',
  InvitationEmailsStarted = 'INVITATION_EMAILS_STARTED',
  ParticipantsAdded = 'PARTICIPANTS_ADDED',
  ParticipantUploadFinished = 'PARTICIPANT_UPLOAD_FINISHED',
  ParticipantUploadProgress = 'PARTICIPANT_UPLOAD_PROGRESS',
  ParticipantUploadStarted = 'PARTICIPANT_UPLOAD_STARTED',
  ReadyForLaunch = 'READY_FOR_LAUNCH',
  ReminderEmailsFinished = 'REMINDER_EMAILS_FINISHED',
  ReminderEmailsProgress = 'REMINDER_EMAILS_PROGRESS',
  ReminderEmailsStarted = 'REMINDER_EMAILS_STARTED',
  ResponseReportCompleted = 'RESPONSE_REPORT_COMPLETED',
  ResponseReportStarted = 'RESPONSE_REPORT_STARTED',
  SurveyCreated = 'SURVEY_CREATED',
  SurveyFinished = 'SURVEY_FINISHED',
  SurveyLaunched = 'SURVEY_LAUNCHED',
  SurveyUpdated = 'SURVEY_UPDATED'
}

export type SurveyInvitationStatus = {
  __typename?: 'SurveyInvitationStatus';
  data?: Maybe<SurveyProgressData>;
  type: SurveyEventType;
};

export type SurveyProgressData = {
  __typename?: 'SurveyProgressData';
  generated: Scalars['Float'];
  participants: Scalars['Float'];
  sent: Scalars['Float'];
};

export type SurveyProgressResponse = {
  __typename?: 'SurveyProgressResponse';
  participantCount: Scalars['Float'];
  surveyInvitationStatus?: Maybe<SurveyInvitationStatus>;
  usersCompletedSurvey: Scalars['Float'];
};

export type SurveyReminders = {
  __typename?: 'SurveyReminders';
  date: Scalars['DateTime'];
  id: Scalars['Float'];
  time: Scalars['Float'];
  timePeriod: Scalars['Float'];
  timeUtc: Scalars['Float'];
  timezone: Scalars['Float'];
  timezoneName: Scalars['String'];
};

export type SurveySchema = {
  __typename?: 'SurveySchema';
  deleted: Scalars['Boolean'];
  emailConfig: Array<EmailConfigModel>;
  id: Scalars['ID'];
  schema: SurveySchemaModel;
  title: Scalars['String'];
  version: Scalars['Float'];
};

export type SurveySchemaModel = {
  __typename?: 'SurveySchemaModel';
  categories: Array<Category>;
};

export type SurveySchemaModelInput = {
  categories: Array<CategoryInput>;
};

export type SurveyWithQuestionOrder = {
  __typename?: 'SurveyWithQuestionOrder';
  archetypeScoreText?: Maybe<Array<ArchetypeScoreText>>;
  deleted: Scalars['Boolean'];
  demographicsRequired: Array<Demographic>;
  id: Scalars['ID'];
  participants?: Maybe<Array<Participant>>;
  questionOrder: Array<QuestionOrder>;
  surveyEventHistory?: Maybe<Array<SurveyEventHistory>>;
  surveyReminders: Array<SurveyReminders>;
  surveySchema: SurveySchema;
  surveySchemaId: Scalars['String'];
  title: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
};

export type TargetCultureInput = {
  behaviourIds: Array<Scalars['Float']>;
  id: Scalars['String'];
  primaryArchetypeId: Scalars['Float'];
  secondaryArchetypeId?: InputMaybe<Scalars['Float']>;
};

export type TeamReport = {
  __typename?: 'TeamReport';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isTargetCulture: Scalars['Boolean'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['String']>;
  reportName: Scalars['String'];
  surveyId: Scalars['ID'];
  teamReportParticipants?: Maybe<Array<TeamReportParticipant>>;
  teamReportPdfs?: Maybe<Array<TeamReportPdf>>;
  updatedAt: Scalars['DateTime'];
};

export type TeamReportParticipant = {
  __typename?: 'TeamReportParticipant';
  id: Scalars['String'];
  participant?: Maybe<Participant>;
  participantId: Scalars['String'];
  reportPdfGcsName?: Maybe<Scalars['String']>;
  teamReportId: Scalars['String'];
};

export type TeamReportPdf = {
  __typename?: 'TeamReportPdf';
  createdAt: Scalars['DateTime'];
  downloadUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: LanguageCodeEnum;
  reportPdfGcsName: Scalars['String'];
  teamReport: TeamReport;
  teamReportId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TemplateInput = {
  body: Scalars['String'];
  linkText: Scalars['String'];
  subject: Scalars['String'];
  type: EmailType;
};

export type Type = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateClientConfigInput = {
  clientId: Scalars['String'];
  template: TemplateInput;
};

export type UpdateClientInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  removeImage: Scalars['Boolean'];
};

export type UpdateParticipantDemographicInput = {
  country: Scalars['String'];
  functionalArea: Scalars['String'];
  industry: Scalars['String'];
  organisationLevel: Scalars['String'];
  participantId: Scalars['String'];
};

export type UpdateProfileInput = {
  dob: Scalars['DateTime'];
  firstName?: InputMaybe<Scalars['String']>;
  gender: GenderEnum;
};

export type UpdateProjectInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateSurveyInput = {
  availableLanguages?: InputMaybe<Array<LanguageCodeEnum>>;
  defaultLanguage?: InputMaybe<LanguageCodeEnum>;
  demographicsRequired?: InputMaybe<Array<CreateDemographicInput>>;
  endDate: Scalars['JSON'];
  id: Scalars['String'];
  launchDate: Scalars['JSON'];
  surveyAdminEmailAddresses?: InputMaybe<Array<Scalars['String']>>;
  surveyViewerEmailAddresses?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type UpdateSurveySchemaInput = {
  emailConfig: Array<EmailConfigInput>;
  id: Scalars['String'];
  schema: SurveySchemaModelInput;
  title: Scalars['String'];
  version: Scalars['Float'];
};

export type UpdateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  role?: UserRole;
  surveyIds: Array<Scalars['String']>;
};

export type UpdateUserRoleInput = {
  roles: Array<UserRole>;
  userId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  id: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
  projects: Array<Project>;
  roles: Array<UserRole>;
  status?: Maybe<UserStatus>;
  userClients?: Maybe<Array<UserClients>>;
  userParticipants?: Maybe<Array<UserParticipants>>;
  verificationTokens?: Maybe<Array<VerificationToken>>;
};

export type UserClients = {
  __typename?: 'UserClients';
  client: Client;
  clientId: Scalars['String'];
  id: Scalars['String'];
  user: User;
  userId: Scalars['Float'];
};

export type UserModel = {
  __typename?: 'UserModel';
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  id: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
  projects: Array<Project>;
  role?: Maybe<UserRole>;
  roles: Array<UserRole>;
  status?: Maybe<UserStatus>;
  userClients?: Maybe<Array<UserClients>>;
  userParticipants?: Maybe<Array<UserParticipants>>;
  verificationTokens?: Maybe<Array<VerificationToken>>;
};

export type UserParticipants = {
  __typename?: 'UserParticipants';
  id: Scalars['String'];
  participant: Participant;
  participantId: Scalars['String'];
  user: User;
  userId: Scalars['Float'];
};

export type UserProjectPermission = {
  __typename?: 'UserProjectPermission';
  assigned: Scalars['Boolean'];
  clientId: Scalars['String'];
  projectId: Scalars['String'];
  projectName: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  error?: Maybe<Scalars['String']>;
  idToken?: Maybe<Scalars['String']>;
  user?: Maybe<UserModel>;
};

/** User Role */
export enum UserRole {
  Admin = 'ADMIN',
  Client = 'CLIENT',
  Consultant = 'CONSULTANT',
  Participant = 'PARTICIPANT',
  User = 'USER'
}

/** User Status */
export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type VerificationToken = {
  __typename?: 'VerificationToken';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isUsed: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['Float'];
};

export type CreateUserMutationVariables = Exact<{
  userOptions: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserResponse', error?: string | null, idToken?: string | null, user?: { __typename?: 'UserModel', firstName?: string | null, lastName?: string | null, email?: string | null, role?: UserRole | null } | null } };

export type EditUserMutationVariables = Exact<{
  userOptions: UpdateUserInput;
  editUserId: Scalars['Float'];
}>;


export type EditUserMutation = { __typename?: 'Mutation', editUser: { __typename?: 'UserResponse', error?: string | null, user?: { __typename?: 'UserModel', firstName?: string | null, lastName?: string | null, email?: string | null, role?: UserRole | null } | null } };

export type CreateSurveyMutationVariables = Exact<{
  options: CreateSurveyInput;
}>;


export type CreateSurveyMutation = { __typename?: 'Mutation', createSurvey: { __typename?: 'Survey', id: string, surveySchemaId: string, title: string, demographicsRequired: Array<{ __typename?: 'Demographic', id: string, title: string }> } };

export type UpdateSurveyMutationVariables = Exact<{
  options: UpdateSurveyInput;
}>;


export type UpdateSurveyMutation = { __typename?: 'Mutation', updateSurvey: { __typename?: 'Survey', id: string, title: string, surveySchemaId: string, demographicsRequired: Array<{ __typename?: 'Demographic', id: string, title: string }> } };

export type InviteUserMutationVariables = Exact<{
  options: InviteUserInput;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: { __typename?: 'User', id: number, email?: string | null, firebaseId: string, firstName?: string | null, lastName?: string | null } };

export type DeleteSurveyMutationVariables = Exact<{
  options: DeleteSurveyInput;
}>;


export type DeleteSurveyMutation = { __typename?: 'Mutation', deleteSurvey: { __typename?: 'Survey', id: string } };

export type UpdateSurveyEmailMutationVariables = Exact<{
  surveyId: Scalars['String'];
  emailType: Scalars['String'];
  data: SurveyEmailInput;
}>;


export type UpdateSurveyEmailMutation = { __typename?: 'Mutation', updateSurveyEmail: { __typename?: 'SurveyEmailResponse', template: { __typename?: 'EmailTemplate', subject: string, body: string } } };

export type ResetSurveyEmailTemplateMutationVariables = Exact<{
  surveyId: Scalars['String'];
  emailType: Scalars['String'];
}>;


export type ResetSurveyEmailTemplateMutation = { __typename?: 'Mutation', resetSurveyEmailTemplate: { __typename?: 'EmailTemplate', subject: string, body: string } };

export type AddSurveyHistoryEventMutationVariables = Exact<{
  surveyEventType: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type AddSurveyHistoryEventMutation = { __typename?: 'Mutation', addSurveyHistoryEvent: { __typename?: 'Survey', id: string } };

export type CreateClientMutationVariables = Exact<{
  options: CreateClientInput;
  image?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createClient: { __typename?: 'ClientResponse', error?: string | null, client?: { __typename?: 'ClientModel', deletedDate?: any | null, id: string, name: string, imageUrl?: string | null } | null } };

export type DeleteClientMutationVariables = Exact<{
  options: DeleteClientInput;
}>;


export type DeleteClientMutation = { __typename?: 'Mutation', deleteClient: { __typename?: 'Client', deletedDate?: any | null, id: string } };

export type UpdateClientMutationVariables = Exact<{
  options: UpdateClientInput;
  image?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient: { __typename?: 'Client', deletedDate?: any | null, id: string, name: string, imageUrl?: string | null } };

export type SaveTargetCultureClientMutationVariables = Exact<{
  options: TargetCultureInput;
}>;


export type SaveTargetCultureClientMutation = { __typename?: 'Mutation', saveTargetCultureClient: { __typename?: 'Client', primaryArchetypeId?: number | null, secondaryArchetypeId?: number | null, behaviours?: Array<{ __typename?: 'Behaviour', id: number }> | null } };

export type AddConsultantsMutationVariables = Exact<{
  options: AddConsultantsInput;
}>;


export type AddConsultantsMutation = { __typename?: 'Mutation', addConsultants: Array<{ __typename?: 'User', id: number, firstName?: string | null, lastName?: string | null, email?: string | null }> };

export type DeleteConsultantMutationVariables = Exact<{
  options: DeleteConsultantInput;
}>;


export type DeleteConsultantMutation = { __typename?: 'Mutation', deleteConsultant: boolean };

export type AssignClientsMutationVariables = Exact<{
  options: AssignClientsInput;
}>;


export type AssignClientsMutation = { __typename?: 'Mutation', assignClients: boolean };

export type ChangeEmailConfigMutationVariables = Exact<{
  options: ChangeEmailConfigInput;
}>;


export type ChangeEmailConfigMutation = { __typename?: 'Mutation', changeEmailConfig: boolean };

export type UpdateClientEmailConfigMutationVariables = Exact<{
  options: UpdateClientConfigInput;
}>;


export type UpdateClientEmailConfigMutation = { __typename?: 'Mutation', updateClientEmailConfig: { __typename?: 'Client', emailConfig: Array<{ __typename?: 'ClientEmailConfig', type: EmailType, subject: string, body: string }> } };

export type SaveGroupReportParticipantMutationVariables = Exact<{
  groupId: Scalars['String'];
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  pdfLanguage: Scalars['String'];
}>;


export type SaveGroupReportParticipantMutation = { __typename?: 'Mutation', saveGroupReportParticipant: { __typename?: 'GroupReportParticipant', id: string, participantId: string, groupReportId: string, language: LanguageCodeEnum } };

export type SaveGroupReportPdfMutationVariables = Exact<{
  groupId: Scalars['String'];
  surveyId: Scalars['String'];
  pdfLanguage: Scalars['String'];
}>;


export type SaveGroupReportPdfMutation = { __typename?: 'Mutation', saveGroupReportPdf: { __typename?: 'GroupReportPdf', id: string, groupReportId: string, language: LanguageCodeEnum } };

export type CreateGroupReportMutationVariables = Exact<{
  options: CreateGroupReportInput;
}>;


export type CreateGroupReportMutation = { __typename?: 'Mutation', createGroupReport: { __typename?: 'GroupReport', id: string, clientId: string, reportName: string } };

export type DeleteGroupReportMutationVariables = Exact<{
  groupReportId: Scalars['String'];
}>;


export type DeleteGroupReportMutation = { __typename?: 'Mutation', deleteGroupReport: boolean };

export type AddParticipantsMutationVariables = Exact<{
  options: AddParticipantsInput;
}>;


export type AddParticipantsMutation = { __typename?: 'Mutation', addParticipants: boolean };

export type AssignParticipantsMutationVariables = Exact<{
  options: AssignParticipantsInput;
}>;


export type AssignParticipantsMutation = { __typename?: 'Mutation', AssignParticipants: boolean };

export type DeleteParticipantMutationVariables = Exact<{
  participantId: Scalars['String'];
  projectId: Scalars['String'];
}>;


export type DeleteParticipantMutation = { __typename?: 'Mutation', deleteParticipant: boolean };

export type CopyOrMoveParticipantsMutationVariables = Exact<{
  options: CopyOrMoveParticipantsInput;
}>;


export type CopyOrMoveParticipantsMutation = { __typename?: 'Mutation', copyOrMoveParticipants: boolean };

export type SendInviteNotificationToParticipantMutationVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  clientId: Scalars['String'];
}>;


export type SendInviteNotificationToParticipantMutation = { __typename?: 'Mutation', sendInviteNotificationToParticipant: boolean };

export type SendReminderToParticipantMutationVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  clientId: Scalars['String'];
}>;


export type SendReminderToParticipantMutation = { __typename?: 'Mutation', sendReminderToParticipant: boolean };

export type CompleteSurveyMutationVariables = Exact<{
  surveyId: Scalars['String'];
  participantId: Scalars['String'];
}>;


export type CompleteSurveyMutation = { __typename?: 'Mutation', completeSurvey: { __typename?: 'CompleteParticipantResponse', message: string } };

export type SaveClientForParticipantMutationVariables = Exact<{
  options: ClientForParticipantInput;
}>;


export type SaveClientForParticipantMutation = { __typename?: 'Mutation', saveClientForParticipant: { __typename?: 'Participant', clients?: Array<{ __typename?: 'Client', id: string, name: string }> | null } };

export type SaveServiceTypeForParticipantMutationVariables = Exact<{
  options: ServiceTypeInput;
}>;


export type SaveServiceTypeForParticipantMutation = { __typename?: 'Mutation', saveServiceTypeForParticipant: { __typename?: 'Participant', serviceTypes: Array<{ __typename?: 'ServiceType', label: string, value: string }> } };

export type SaveAnswerMutationVariables = Exact<{
  options: CreateSurveyAnswerInput;
}>;


export type SaveAnswerMutation = { __typename?: 'Mutation', saveAnswer: { __typename?: 'Participant', id: string, responses: Array<{ __typename?: 'Response', questionId: string, answer?: Array<{ __typename?: 'RankedAnswer', optionId: string, order: number }> | null }> } };

export type SaveDemographicsMutationVariables = Exact<{
  options: UpdateParticipantDemographicInput;
}>;


export type SaveDemographicsMutation = { __typename?: 'Mutation', saveDemographics: { __typename?: 'Participant', id: string } };

export type AddParticipantEventHistoryMutationVariables = Exact<{
  options: AddParticipantHistoryEventInput;
}>;


export type AddParticipantEventHistoryMutation = { __typename?: 'Mutation', addParticipantEventHistory: { __typename?: 'ParticipantEventHistory', id: string, surveyId: string, type: ParticipantEventType, data?: any | null } };

export type ImportParticipantsMutationVariables = Exact<{
  surveyId: Scalars['String'];
  projectId: Scalars['String'];
  clientId: Scalars['String'];
  input: ImportParticipantInput;
}>;


export type ImportParticipantsMutation = { __typename?: 'Mutation', importParticipants: { __typename?: 'ImportCSVUsersResponse', importId: string, status: string, rows: number } };

export type CreateProjectMutationVariables = Exact<{
  options: CreateProjectInput;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any } };

export type UpdateProjectNameMutationVariables = Exact<{
  options: UpdateProjectInput;
}>;


export type UpdateProjectNameMutation = { __typename?: 'Mutation', updateProjectName: { __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any } };

export type DeleteProjectMutationVariables = Exact<{
  options: DeleteProjectInput;
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject: boolean };

export type AssignProjectsMutationVariables = Exact<{
  options: AssignProjectsInput;
}>;


export type AssignProjectsMutation = { __typename?: 'Mutation', assignProjects: boolean };

export type RegisterUserMutationVariables = Exact<{
  options: RegisterInput;
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', register: { __typename?: 'UserResponse', error?: string | null, idToken?: string | null, user?: { __typename?: 'UserModel', firstName?: string | null, lastName?: string | null, firebaseId: string } | null } };

export type SetPasswordMutationVariables = Exact<{
  options: SetPasswordInput;
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword: { __typename?: 'UserResponse', error?: string | null } };

export type SetPasswordForInvitedUserMutationVariables = Exact<{
  options: SetPasswordInput;
}>;


export type SetPasswordForInvitedUserMutation = { __typename?: 'Mutation', setPasswordForInvitedUser: boolean };

export type CreateResponseReportMutationVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type CreateResponseReportMutation = { __typename?: 'Mutation', createResponseReport: string };

export type CreateTeamReportMutationVariables = Exact<{
  options: CreateTeamReportInput;
}>;


export type CreateTeamReportMutation = { __typename?: 'Mutation', createTeamReport: { __typename?: 'TeamReport', id: string, reportName: string } };

export type DeleteTeamReportMutationVariables = Exact<{
  teamReportId: Scalars['String'];
}>;


export type DeleteTeamReportMutation = { __typename?: 'Mutation', deleteTeamReport: boolean };

export type CreateTeamReportPdfMutationVariables = Exact<{
  teamReportId: Scalars['String'];
  surveyId: Scalars['String'];
  pdfLanguage: Scalars['String'];
}>;


export type CreateTeamReportPdfMutation = { __typename?: 'Mutation', createTeamReportPdf: { __typename?: 'TeamReportPdf', id: string, teamReportId: string, language: LanguageCodeEnum, downloadUrl?: string | null } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type ConsultantsQueryVariables = Exact<{
  options?: InputMaybe<PagedQueryInput>;
}>;


export type ConsultantsQuery = { __typename?: 'Query', consultants: { __typename?: 'PagedUsers', pageOffset: number, totalCount: number, results: Array<{ __typename?: 'User', id: number, email?: string | null, firstName?: string | null, lastName?: string | null }> } };

export type GetArchetypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetArchetypesQuery = { __typename?: 'Query', getArchetypes: Array<{ __typename?: 'Archetype', id: number, name: string, priority: number, behaviours?: Array<{ __typename?: 'Behaviour', id: number, name: string, priority: number, interviewQuestions?: Array<string> | null }> | null }> };

export type GetArchetypesWithTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetArchetypesWithTokenQuery = { __typename?: 'Query', getArchetypesWithToken: Array<{ __typename?: 'Archetype', id: number, name: string, priority: number, behaviours?: Array<{ __typename?: 'Behaviour', id: number, name: string, priority: number, interviewQuestions?: Array<string> | null }> | null }> };

export type ClientsQueryVariables = Exact<{
  options?: InputMaybe<GetClientsQueryInput>;
  sort?: InputMaybe<SortInput>;
  searchTerm?: InputMaybe<Scalars['String']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type ClientsQuery = { __typename?: 'Query', clients: { __typename?: 'PagedClientsResponse', pageSize: number, pageOffset: number, totalCount: number, results: Array<{ __typename?: 'ClientModel', id: string, imageUrl?: string | null, name: string, createdAt: any, deletedDate?: any | null, userClients?: Array<{ __typename?: 'UserClients', id: string, clientId: string }> | null, projects?: Array<{ __typename?: 'Project', id: string, name: string }> | null }> } };

export type ClientQueryVariables = Exact<{
  options: QueryClientInput;
}>;


export type ClientQuery = { __typename?: 'Query', client: { __typename?: 'QueryClientResponse', client?: { __typename?: 'ClientModel', deletedDate?: any | null, id: string, name: string, imageUrl?: string | null, primaryArchetypeId?: number | null, secondaryArchetypeId?: number | null, isConfigEnabled: boolean, emailConfig: Array<{ __typename?: 'ClientEmailConfig', type: EmailType, subject: string, body: string, linkText?: string | null }>, behaviours?: Array<{ __typename?: 'Behaviour', id: number, archetypeId: number, name: string }> | null, groupReports?: Array<{ __typename?: 'GroupReport', id: string }> | null } | null } };

export type ClientForParticipantQueryVariables = Exact<{
  options: QueryClientInput;
}>;


export type ClientForParticipantQuery = { __typename?: 'Query', clientForParticipant: { __typename?: 'QueryParticipantClientResponse', imageUrl?: string | null, name: string } };

export type GetGroupReportQueryVariables = Exact<{
  groupId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type GetGroupReportQuery = { __typename?: 'Query', getGroupReport: { __typename?: 'GroupReport', id: string, reportName: string, clientId: string, primaryArchetypeId: number, secondaryArchetypeId?: number | null, client: { __typename?: 'Client', id: string, name: string, imageUrl?: string | null }, groupReportParticipants?: Array<{ __typename?: 'GroupReportParticipant', id: string, participantId: string, reportPdfGcsName?: string | null, participant: { __typename?: 'Participant', demographics: Array<{ __typename?: 'DemographicResponse', id: string, title: string, value?: string | null }> } }> | null, behaviours?: Array<{ __typename?: 'Behaviour', id: number }> | null } };

export type GetGroupReportsQueryVariables = Exact<{
  options: GetGroupReportsInput;
}>;


export type GetGroupReportsQuery = { __typename?: 'Query', getGroupReports: { __typename?: 'PagedGroupReports', pageOffset: number, pageSize: number, totalCount: number, results: Array<{ __typename?: 'GroupReport', id: string, reportName: string, clientId: string, surveyId: string, createdAt: any, updatedAt: any, client: { __typename?: 'Client', name: string } }> } };

export type GetGroupReportWithTokenQueryVariables = Exact<{
  groupId: Scalars['String'];
  participantId?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetGroupReportWithTokenQuery = { __typename?: 'Query', getGroupReportWithToken: { __typename?: 'GroupReport', reportName: string, clientId: string, primaryArchetypeId: number, secondaryArchetypeId?: number | null, client: { __typename?: 'Client', id: string, name: string, imageUrl?: string | null }, groupReportParticipants?: Array<{ __typename?: 'GroupReportParticipant', id: string, participantId: string, reportPdfGcsName?: string | null, participant: { __typename?: 'Participant', demographics: Array<{ __typename?: 'DemographicResponse', id: string, title: string, value?: string | null }> } }> | null, behaviours?: Array<{ __typename?: 'Behaviour', id: number }> | null } };

export type GroupBehaviorProfileQueryVariables = Exact<{
  options: GroupBehaviorProfileInput;
}>;


export type GroupBehaviorProfileQuery = { __typename?: 'Query', groupBehaviorProfile: Array<{ __typename?: 'HexagonalDiagramData', archetypeId: number, behaviorId: number, fontColor: string, fontBold?: boolean | null, label: string, value: number, color?: string | null, showLine?: boolean | null, priority?: number | null }> };

export type GroupBehaviorPreferencesQueryVariables = Exact<{
  options: GroupBehaviorPreferencesInput;
}>;


export type GroupBehaviorPreferencesQuery = { __typename?: 'Query', groupBehaviorPreferences: Array<{ __typename?: 'GroupBehaviorPreferences', participantId: string, participantName: string, archetypeItems: Array<{ __typename?: 'ArchetypeItem', archetypeId: number, title: string, dataList: Array<{ __typename?: 'DataItem', priority?: number | null, label: string, color: string, value: number, shouldHighlightSection: boolean, fontColor?: string | null }> }> }> };

export type GroupArchetypePreferencesQueryVariables = Exact<{
  options: GroupArchetypePreferencesInput;
}>;


export type GroupArchetypePreferencesQuery = { __typename?: 'Query', groupArchetypePreferences: Array<{ __typename?: 'GroupArchetypePreferences', participantName: string, dataList: Array<{ __typename?: 'ArchetypeDataItem', archetypeId: number, label: string, color: string, value: number, fontColor?: string | null }> }> };

export type GetGroupParticipantReportQueryVariables = Exact<{
  surveyId: Scalars['String'];
  groupId: Scalars['String'];
  participantId: Scalars['String'];
  pdfLanguage: Scalars['String'];
}>;


export type GetGroupParticipantReportQuery = { __typename?: 'Query', getGroupParticipantReport: { __typename?: 'GroupReportParticipant', id: string, groupReportId: string, participantId: string, language: LanguageCodeEnum, reportPdfGcsName?: string | null } };

export type GetGroupReportPdfQueryVariables = Exact<{
  surveyId: Scalars['String'];
  groupId: Scalars['String'];
  pdfLanguage: Scalars['String'];
}>;


export type GetGroupReportPdfQuery = { __typename?: 'Query', getGroupReportPdf: { __typename?: 'GroupReportPdf', id: string, groupReportId: string, language: LanguageCodeEnum, reportPdfGcsName: string } };

export type GetIndividualReportPdfQueryVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  pdfLanguage: Scalars['String'];
  isTargetCulture: Scalars['Boolean'];
  clientId?: InputMaybe<Scalars['String']>;
}>;


export type GetIndividualReportPdfQuery = { __typename?: 'Query', getIndividualReportPdf: { __typename?: 'IndividualReport', id: string, participantId: string, surveyId: string, language: LanguageCodeEnum, reportPdfGcsName: string } };

export type ItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemsQuery = { __typename?: 'Query', items: Array<{ __typename?: 'Item', id: number, label: string }> };

export type ParticipantQueryVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ParticipantQuery = { __typename?: 'Query', participant: { __typename?: 'Participant', id: string, clients?: Array<{ __typename?: 'Client', id: string, name: string, deletedDate?: any | null, primaryArchetypeId?: number | null, secondaryArchetypeId?: number | null, behaviours?: Array<{ __typename?: 'Behaviour', id: number, archetypeId: number }> | null }> | null, demographics: Array<{ __typename?: 'DemographicResponse', id: string, title: string, value?: string | null }>, userParticipants?: Array<{ __typename?: 'UserParticipants', id: string, participantId: string }> | null } };

export type ParticipantWithTokenQueryVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  token: Scalars['String'];
}>;


export type ParticipantWithTokenQuery = { __typename?: 'Query', participantWithToken: { __typename?: 'Participant', id: string, clients?: Array<{ __typename?: 'Client', id: string, name: string, deletedDate?: any | null, primaryArchetypeId?: number | null, secondaryArchetypeId?: number | null, behaviours?: Array<{ __typename?: 'Behaviour', id: number, archetypeId: number }> | null }> | null, demographics: Array<{ __typename?: 'DemographicResponse', id: string, title: string, value?: string | null }> } };

export type ParticipantWithEventHistoryQueryVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ParticipantWithEventHistoryQuery = { __typename?: 'Query', participantWithEventHistory: { __typename?: 'Participant', id: string, participantEventHistory?: Array<{ __typename?: 'ParticipantEventHistory', createdAt: any, type: ParticipantEventType, data?: any | null }> | null } };

export type ParticipantsQueryVariables = Exact<{
  options: GetPagedParticipantInput;
}>;


export type ParticipantsQuery = { __typename?: 'Query', participants: { __typename?: 'PagedParticipants', pageOffset: number, pageSize: number, totalCount: number, results: Array<{ __typename?: 'Participant', id: string, surveyStatus?: ParticipantEventType | null, testAccount: boolean, surveyProgress?: number | null, createdAt: any, user: { __typename?: 'User', id: number, email?: string | null }, demographics: Array<{ __typename?: 'DemographicResponse', title: string, value?: string | null }>, clients?: Array<{ __typename?: 'Client', id: string, name: string, deletedDate?: any | null, primaryArchetypeId?: number | null, behaviours?: Array<{ __typename?: 'Behaviour', id: number }> | null }> | null, serviceTypes: Array<{ __typename?: 'ServiceType', label: string, value: string }>, userParticipants?: Array<{ __typename?: 'UserParticipants', id: string, participantId: string }> | null }> } };

export type ParticipantsLightWeightQueryVariables = Exact<{
  options: GetPagedParticipantInput;
}>;


export type ParticipantsLightWeightQuery = { __typename?: 'Query', participantsLightWeight: { __typename?: 'PagedParticipants', pageOffset: number, pageSize: number, totalCount: number, results: Array<{ __typename?: 'Participant', id: string, surveyStatus?: ParticipantEventType | null, surveyProgress?: number | null, createdAt: any, user: { __typename?: 'User', id: number, email?: string | null }, demographics: Array<{ __typename?: 'DemographicResponse', title: string, value?: string | null }>, clients?: Array<{ __typename?: 'Client', id: string, name: string }> | null, serviceTypes: Array<{ __typename?: 'ServiceType', label: string, value: string }> }> } };

export type AllParticipantsLightWeightQueryVariables = Exact<{
  options: GetPagedParticipantInput;
}>;


export type AllParticipantsLightWeightQuery = { __typename?: 'Query', participantsLightWeight: { __typename?: 'PagedParticipants', pageOffset: number, pageSize: number, totalCount: number, results: Array<{ __typename?: 'Participant', id: string, surveyStatus?: ParticipantEventType | null, surveyProgress?: number | null, createdAt: any, user: { __typename?: 'User', id: number, email?: string | null }, demographics: Array<{ __typename?: 'DemographicResponse', title: string, value?: string | null }>, projects: Array<{ __typename?: 'Project', id: string, name: string, client: { __typename?: 'Client', id: string, name: string } }>, serviceTypes: Array<{ __typename?: 'ServiceType', label: string, value: string }> }> } };

export type SendInviteNotificationsMutationVariables = Exact<{
  surveyId: Scalars['String'];
  importId: Scalars['String'];
}>;


export type SendInviteNotificationsMutation = { __typename?: 'Mutation', sendInviteNotifications: boolean };

export type ParticipantSurveysQueryVariables = Exact<{
  options?: InputMaybe<PagedParticipantInput>;
}>;


export type ParticipantSurveysQuery = { __typename?: 'Query', participantSurveys: { __typename?: 'PagedParticipants', totalCount: number, pageOffset: number, pageSize: number, results: Array<{ __typename?: 'Participant', id: string, surveyProgress?: number | null, participantEventHistory?: Array<{ __typename?: 'ParticipantEventHistory', createdAt: any, type: ParticipantEventType, data?: any | null }> | null, survey: { __typename?: 'Survey', id: string, title: string, deleted: boolean, surveySchemaId: string } }> } };

export type ParticipantWithSurveyQueryVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ParticipantWithSurveyQuery = { __typename?: 'Query', participantWithSurvey: { __typename?: 'GetParticipant', survey: { __typename?: 'SurveyWithQuestionOrder', id: string, title: string, questionOrder: Array<{ __typename?: 'QuestionOrder', type: string, id: string, index: number, orderIndex?: number | null, questionGroupIndex?: number | null, categoryIndex?: number | null }>, surveySchema: { __typename?: 'SurveySchema', schema: { __typename?: 'SurveySchemaModel', categories: Array<{ __typename?: 'Category', id: string, title: string, order: number, name: string, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, title: string, name: string, order: number, description: string, questions: Array<{ __typename?: 'Question', id: string, questionText: string, type: QuestionType, order: number, options?: Array<{ __typename?: 'Options', id: string, order: number, relatedId?: string | null, score: number, text: string, behaviourId?: number | null }> | null }> }> }> } } }, participant: { __typename?: 'Participant', id: string, surveyStatus?: ParticipantEventType | null, demographics: Array<{ __typename?: 'DemographicResponse', id: string, title: string, value?: string | null }>, responses: Array<{ __typename?: 'Response', categoryId: string, questionGroupId: string, questionId: string, answer?: Array<{ __typename?: 'RankedAnswer', optionId: string, order: number }> | null }>, clients?: Array<{ __typename?: 'Client', id: string, name: string }> | null } } };

export type ParseCsvUsersQueryVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type ParseCsvUsersQuery = { __typename?: 'Query', parseCSVUsers: { __typename?: 'ParseCSVUsersResponse', parsedRecords?: Array<{ __typename?: 'ParsedRecord', data?: Array<{ __typename?: 'ImportData', key?: string | null, value?: string | null }> | null }> | null, errors?: Array<{ __typename?: 'CSVUsersError', row: number, message: string }> | null } };

export type ParticipantEventHistoryQueryVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ParticipantEventHistoryQuery = { __typename?: 'Query', participantEventHistory: Array<{ __typename?: 'ParticipantEventHistory', id: string, surveyId: string, type: ParticipantEventType, data?: any | null }> };

export type GetDuplicateParticipantsQueryVariables = Exact<{
  options: GetDuplicateParticipants;
}>;


export type GetDuplicateParticipantsQuery = { __typename?: 'Query', getDuplicateParticipants: Array<{ __typename?: 'Participant', id: string, user: { __typename?: 'User', id: number, email?: string | null }, demographics: Array<{ __typename?: 'DemographicResponse', id: string, title: string, value?: string | null }>, clients?: Array<{ __typename?: 'Client', id: string, name: string }> | null, projects: Array<{ __typename?: 'Project', id: string, name: string }> }> };

export type GetClientProjectsQueryVariables = Exact<{
  options: GetProjectsInput;
}>;


export type GetClientProjectsQuery = { __typename?: 'Query', getClientProjects: { __typename?: 'PagedProjects', pageSize: number, totalCount: number, results: Array<{ __typename?: 'Project', id: string, name: string, createdAt: any, updatedAt: any, participantCount: number, participantCompletedCount: number }> } };

export type GetClientProjectInfoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetClientProjectInfoQuery = { __typename?: 'Query', getClientProject: { __typename?: 'Project', id: string, name: string } };

export type GetAdminProjectsQueryVariables = Exact<{
  options: GetAdminProjectsInput;
}>;


export type GetAdminProjectsQuery = { __typename?: 'Query', getAdminProjects: Array<{ __typename?: 'UserProjectPermission', projectId: string, projectName: string, assigned: boolean, clientId: string }> };

export type ReportingForParticipantQueryVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  languageCode?: InputMaybe<Scalars['String']>;
}>;


export type ReportingForParticipantQuery = { __typename?: 'Query', reportingForParticipant: Array<{ __typename?: 'Reporting', id: string, surveyId: string, participantId: string, archetypeId: number, behaviourId: number, score: number, scoreText: string }> };

export type ReportingForParticipantWithTokenQueryVariables = Exact<{
  participantId: Scalars['String'];
  surveyId: Scalars['String'];
  token: Scalars['String'];
  languageCode?: InputMaybe<Scalars['String']>;
}>;


export type ReportingForParticipantWithTokenQuery = { __typename?: 'Query', reportingForParticipantWithToken: Array<{ __typename?: 'Reporting', id: string, surveyId: string, participantId: string, archetypeId: number, behaviourId: number, score: number, scoreText: string }> };

export type ReportingForGroupQueryVariables = Exact<{
  groupId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ReportingForGroupQuery = { __typename?: 'Query', reportingForGroup: Array<{ __typename?: 'Reporting', id: string, participantId: string, archetypeId: number, behaviourId: number, score: number, scoreText: string, surveyId: string }> };

export type ReportingForGroupWithTokenQueryVariables = Exact<{
  token: Scalars['String'];
  groupId: Scalars['String'];
  surveyId: Scalars['String'];
  participantId?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
}>;


export type ReportingForGroupWithTokenQuery = { __typename?: 'Query', reportingForGroupWithToken: Array<{ __typename?: 'Reporting', id: string, participantId: string, archetypeId: number, behaviourId: number, score: number, scoreText: string, surveyId: string }> };

export type ReportingForTeamQueryVariables = Exact<{
  teamReportId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ReportingForTeamQuery = { __typename?: 'Query', reportingForTeam: Array<{ __typename?: 'Reporting', id: string, participantId: string, archetypeId: number, behaviourId: number, score: number, scoreText: string, surveyId: string }> };

export type ReportingForTeamWithTokenQueryVariables = Exact<{
  token: Scalars['String'];
  teamReportId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type ReportingForTeamWithTokenQuery = { __typename?: 'Query', reportingForTeamWithToken: Array<{ __typename?: 'Reporting', id: string, participantId: string, archetypeId: number, behaviourId: number, score: number, scoreText: string, surveyId: string, participant?: { __typename?: 'Participant', id: string, user: { __typename?: 'User', id: number, firstName?: string | null, lastName?: string | null }, demographics: Array<{ __typename?: 'DemographicResponse', id: string, value?: string | null, title: string }> } | null }> };

export type GetReportPdfDownloadUrlQueryVariables = Exact<{
  reportPdfGcsName: Scalars['String'];
}>;


export type GetReportPdfDownloadUrlQuery = { __typename?: 'Query', getReportPdfDownloadUrl: string };

export type SecretItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type SecretItemsQuery = { __typename?: 'Query', secretItems: Array<{ __typename?: 'SecretItem', id: number, label: string, description: string }> };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { __typename?: 'Query', self: { __typename?: 'User', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, firebaseId: string, gender?: GenderEnum | null, dob?: any | null, roles: Array<UserRole> } };

export type SuveySchemasQueryVariables = Exact<{ [key: string]: never; }>;


export type SuveySchemasQuery = { __typename?: 'Query', suveySchemas: Array<{ __typename?: 'SurveySchema', id: string, title: string, version: number, schema: { __typename?: 'SurveySchemaModel', categories: Array<{ __typename?: 'Category', questionGroups: Array<{ __typename?: 'QuestionGroup', id: string }> }> } }> };

export type SurveysQueryVariables = Exact<{
  options?: InputMaybe<GetSurveysByClientQueryInput>;
}>;


export type SurveysQuery = { __typename?: 'Query', surveys: { __typename?: 'PagedSurveysResponse', pageOffset: number, pageSize: number, totalCount: number, results: Array<{ __typename?: 'Survey', id: string, title: string }> } };

export type SurveyQueryVariables = Exact<{
  options: GetSurveyQueryInput;
}>;


export type SurveyQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', id: string, surveySchemaId: string, title: string, demographicsRequired: Array<{ __typename?: 'Demographic', id: string, title: string }>, surveySchema: { __typename?: 'SurveySchema', schema: { __typename?: 'SurveySchemaModel', categories: Array<{ __typename?: 'Category', id: string, name: string, title: string, order: number, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, name: string, title: string, description: string, order: number, questions: Array<{ __typename?: 'Question', id: string, type: QuestionType, order: number, defaultValueIfUnanswered?: string | null, isOptional?: boolean | null, questionText: string, options?: Array<{ __typename?: 'Options', id: string, text: string }> | null }> }> }> } }, surveyEventHistory?: Array<{ __typename?: 'SurveyEventHistory', type: SurveyEventType }> | null } | null };

export type SurveyWithTokenQueryVariables = Exact<{
  options: GetSurveyQueryInput;
  token: Scalars['String'];
}>;


export type SurveyWithTokenQuery = { __typename?: 'Query', surveyWithToken?: { __typename?: 'Survey', id: string, surveySchemaId: string, title: string, demographicsRequired: Array<{ __typename?: 'Demographic', id: string, title: string }>, surveySchema: { __typename?: 'SurveySchema', schema: { __typename?: 'SurveySchemaModel', categories: Array<{ __typename?: 'Category', id: string, name: string, title: string, order: number, questionGroups: Array<{ __typename?: 'QuestionGroup', id: string, name: string, title: string, description: string, order: number, questions: Array<{ __typename?: 'Question', id: string, type: QuestionType, order: number, defaultValueIfUnanswered?: string | null, isOptional?: boolean | null, questionText: string, options?: Array<{ __typename?: 'Options', id: string, text: string }> | null }> }> }> } }, surveyEventHistory?: Array<{ __typename?: 'SurveyEventHistory', type: SurveyEventType }> | null } | null };

export type GetSurveyProgressQueryVariables = Exact<{
  surveyId: Scalars['String'];
}>;


export type GetSurveyProgressQuery = { __typename?: 'Query', getSurveyProgress: { __typename?: 'SurveyProgressResponse', usersCompletedSurvey: number, participantCount: number, surveyInvitationStatus?: { __typename?: 'SurveyInvitationStatus', type: SurveyEventType, data?: { __typename?: 'SurveyProgressData', generated: number, sent: number, participants: number } | null } | null } };

export type GetSurveyEmailTemplateQueryVariables = Exact<{
  surveyId: Scalars['String'];
  emailType: Scalars['String'];
}>;


export type GetSurveyEmailTemplateQuery = { __typename?: 'Query', getSurveyEmailTemplate: { __typename?: 'SurveyEmailResponse', template: { __typename?: 'EmailTemplate', subject: string, body: string, linkText?: string | null }, reminders?: Array<{ __typename?: 'SurveyReminders', id: number, time: number, timezoneName: string, timePeriod: number }> | null } };

export type GetTeamReportQueryVariables = Exact<{
  teamReportId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type GetTeamReportQuery = { __typename?: 'Query', getTeamReport: { __typename?: 'TeamReport', id: string, reportName: string, surveyId: string, createdAt: any, updatedAt: any, clientId?: string | null, client?: { __typename?: 'Client', primaryArchetypeId?: number | null, secondaryArchetypeId?: number | null } | null, teamReportParticipants?: Array<{ __typename?: 'TeamReportParticipant', id: string, teamReportId: string, participantId: string, reportPdfGcsName?: string | null, participant?: { __typename?: 'Participant', user: { __typename?: 'User', firstName?: string | null, lastName?: string | null }, demographics: Array<{ __typename?: 'DemographicResponse', id: string, title: string, value?: string | null }> } | null }> | null } };

export type GetTeamReportWithTokenQueryVariables = Exact<{
  token: Scalars['String'];
  teamReportId: Scalars['String'];
  surveyId: Scalars['String'];
}>;


export type GetTeamReportWithTokenQuery = { __typename?: 'Query', getTeamReportWithToken: { __typename?: 'TeamReport', reportName: string, clientId?: string | null, client?: { __typename?: 'Client', primaryArchetypeId?: number | null, secondaryArchetypeId?: number | null, behaviours?: Array<{ __typename?: 'Behaviour', id: number, archetypeId: number }> | null } | null, teamReportParticipants?: Array<{ __typename?: 'TeamReportParticipant', id: string, participantId: string, reportPdfGcsName?: string | null, participant?: { __typename?: 'Participant', reportings?: Array<{ __typename?: 'Reporting', id: string, behaviourId: number, archetypeId: number, score: number }> | null, user: { __typename?: 'User', id: number, firstName?: string | null, lastName?: string | null }, demographics: Array<{ __typename?: 'DemographicResponse', id: string, title: string, value?: string | null }> } | null }> | null } };

export type GetTeamReportsQueryVariables = Exact<{
  options: GetTeamReportsInput;
}>;


export type GetTeamReportsQuery = { __typename?: 'Query', getTeamReports: { __typename?: 'PagedTeamReports', pageSize: number, pageOffset: number, totalCount: number, results: Array<{ __typename?: 'TeamReport', id: string, reportName: string, surveyId: string, createdAt: any, updatedAt: any, clientId?: string | null, isTargetCulture: boolean }> } };

export type GetTeamReportPdfQueryVariables = Exact<{
  surveyId: Scalars['String'];
  teamReportId: Scalars['String'];
  pdfLanguage: Scalars['String'];
  isTargetCulture: Scalars['Boolean'];
}>;


export type GetTeamReportPdfQuery = { __typename?: 'Query', getTeamReportPdf: { __typename?: 'TeamReportPdf', id: string, teamReportId: string, language: LanguageCodeEnum, reportPdfGcsName: string } };

export type UsersQueryVariables = Exact<{
  options: GetUsersQueryInput;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'PagedUsersResponse', pageSize: number, pageOffset: number, totalCount: number, results: Array<{ __typename?: 'UserModel', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, role?: UserRole | null, status?: UserStatus | null }> } };

export type UserQueryVariables = Exact<{
  options: QueryUserInput;
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'QueryUserResponse', user?: { __typename?: 'UserModel', id: number, firebaseId: string, firstName?: string | null, lastName?: string | null, email?: string | null, role?: UserRole | null, gender?: GenderEnum | null, dob?: any | null } | null } };

export type IsUserEmailVerifiedQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsUserEmailVerifiedQuery = { __typename?: 'Query', isUserEmailVerified: boolean };

export type UpdateProfileMutationVariables = Exact<{
  options: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'User', id: number } };

export type GetCtaUrlForUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetCtaUrlForUserQuery = { __typename?: 'Query', getCtaUrlForUser: string };

export type GetUserByTokenQueryVariables = Exact<{
  options: GetUserByTokenInput;
}>;


export type GetUserByTokenQuery = { __typename?: 'Query', getUserByToken: { __typename?: 'User', id: number, roles: Array<UserRole>, email?: string | null, firstName?: string | null, lastName?: string | null } };


export const CreateUserDocument = gql`
    mutation CreateUser($userOptions: CreateUserInput!) {
  createUser(userOptions: $userOptions) {
    error
    idToken
    user {
      firstName
      lastName
      email
      role
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userOptions: // value for 'userOptions'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const EditUserDocument = gql`
    mutation EditUser($userOptions: UpdateUserInput!, $editUserId: Float!) {
  editUser(userOptions: $userOptions, id: $editUserId) {
    error
    user {
      firstName
      lastName
      email
      role
    }
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      userOptions: // value for 'userOptions'
 *      editUserId: // value for 'editUserId'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const CreateSurveyDocument = gql`
    mutation CreateSurvey($options: CreateSurveyInput!) {
  createSurvey(options: $options) {
    id
    demographicsRequired {
      id
      title
    }
    surveySchemaId
    title
  }
}
    `;
export type CreateSurveyMutationFn = Apollo.MutationFunction<CreateSurveyMutation, CreateSurveyMutationVariables>;

/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyMutation, CreateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyMutation, CreateSurveyMutationVariables>(CreateSurveyDocument, options);
      }
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<CreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSurveyMutation, CreateSurveyMutationVariables>;
export const UpdateSurveyDocument = gql`
    mutation UpdateSurvey($options: UpdateSurveyInput!) {
  updateSurvey(options: $options) {
    id
    title
    demographicsRequired {
      id
      title
    }
    surveySchemaId
  }
}
    `;
export type UpdateSurveyMutationFn = Apollo.MutationFunction<UpdateSurveyMutation, UpdateSurveyMutationVariables>;

/**
 * __useUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyMutation, { data, loading, error }] = useUpdateSurveyMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyMutation, UpdateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyMutation, UpdateSurveyMutationVariables>(UpdateSurveyDocument, options);
      }
export type UpdateSurveyMutationHookResult = ReturnType<typeof useUpdateSurveyMutation>;
export type UpdateSurveyMutationResult = Apollo.MutationResult<UpdateSurveyMutation>;
export type UpdateSurveyMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyMutation, UpdateSurveyMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($options: InviteUserInput!) {
  inviteUser(options: $options) {
    id
    email
    firebaseId
    firstName
    lastName
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const DeleteSurveyDocument = gql`
    mutation DeleteSurvey($options: DeleteSurveyInput!) {
  deleteSurvey(options: $options) {
    id
  }
}
    `;
export type DeleteSurveyMutationFn = Apollo.MutationFunction<DeleteSurveyMutation, DeleteSurveyMutationVariables>;

/**
 * __useDeleteSurveyMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyMutation, { data, loading, error }] = useDeleteSurveyMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteSurveyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyMutation, DeleteSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyMutation, DeleteSurveyMutationVariables>(DeleteSurveyDocument, options);
      }
export type DeleteSurveyMutationHookResult = ReturnType<typeof useDeleteSurveyMutation>;
export type DeleteSurveyMutationResult = Apollo.MutationResult<DeleteSurveyMutation>;
export type DeleteSurveyMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyMutation, DeleteSurveyMutationVariables>;
export const UpdateSurveyEmailDocument = gql`
    mutation updateSurveyEmail($surveyId: String!, $emailType: String!, $data: SurveyEmailInput!) {
  updateSurveyEmail(surveyId: $surveyId, emailType: $emailType, data: $data) {
    template {
      subject
      body
    }
  }
}
    `;
export type UpdateSurveyEmailMutationFn = Apollo.MutationFunction<UpdateSurveyEmailMutation, UpdateSurveyEmailMutationVariables>;

/**
 * __useUpdateSurveyEmailMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyEmailMutation, { data, loading, error }] = useUpdateSurveyEmailMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      emailType: // value for 'emailType'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSurveyEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyEmailMutation, UpdateSurveyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyEmailMutation, UpdateSurveyEmailMutationVariables>(UpdateSurveyEmailDocument, options);
      }
export type UpdateSurveyEmailMutationHookResult = ReturnType<typeof useUpdateSurveyEmailMutation>;
export type UpdateSurveyEmailMutationResult = Apollo.MutationResult<UpdateSurveyEmailMutation>;
export type UpdateSurveyEmailMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyEmailMutation, UpdateSurveyEmailMutationVariables>;
export const ResetSurveyEmailTemplateDocument = gql`
    mutation resetSurveyEmailTemplate($surveyId: String!, $emailType: String!) {
  resetSurveyEmailTemplate(surveyId: $surveyId, emailType: $emailType) {
    subject
    body
  }
}
    `;
export type ResetSurveyEmailTemplateMutationFn = Apollo.MutationFunction<ResetSurveyEmailTemplateMutation, ResetSurveyEmailTemplateMutationVariables>;

/**
 * __useResetSurveyEmailTemplateMutation__
 *
 * To run a mutation, you first call `useResetSurveyEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSurveyEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSurveyEmailTemplateMutation, { data, loading, error }] = useResetSurveyEmailTemplateMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      emailType: // value for 'emailType'
 *   },
 * });
 */
export function useResetSurveyEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ResetSurveyEmailTemplateMutation, ResetSurveyEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetSurveyEmailTemplateMutation, ResetSurveyEmailTemplateMutationVariables>(ResetSurveyEmailTemplateDocument, options);
      }
export type ResetSurveyEmailTemplateMutationHookResult = ReturnType<typeof useResetSurveyEmailTemplateMutation>;
export type ResetSurveyEmailTemplateMutationResult = Apollo.MutationResult<ResetSurveyEmailTemplateMutation>;
export type ResetSurveyEmailTemplateMutationOptions = Apollo.BaseMutationOptions<ResetSurveyEmailTemplateMutation, ResetSurveyEmailTemplateMutationVariables>;
export const AddSurveyHistoryEventDocument = gql`
    mutation AddSurveyHistoryEvent($surveyEventType: String!, $surveyId: String!) {
  addSurveyHistoryEvent(surveyEventType: $surveyEventType, surveyId: $surveyId) {
    id
  }
}
    `;
export type AddSurveyHistoryEventMutationFn = Apollo.MutationFunction<AddSurveyHistoryEventMutation, AddSurveyHistoryEventMutationVariables>;

/**
 * __useAddSurveyHistoryEventMutation__
 *
 * To run a mutation, you first call `useAddSurveyHistoryEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyHistoryEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyHistoryEventMutation, { data, loading, error }] = useAddSurveyHistoryEventMutation({
 *   variables: {
 *      surveyEventType: // value for 'surveyEventType'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useAddSurveyHistoryEventMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyHistoryEventMutation, AddSurveyHistoryEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyHistoryEventMutation, AddSurveyHistoryEventMutationVariables>(AddSurveyHistoryEventDocument, options);
      }
export type AddSurveyHistoryEventMutationHookResult = ReturnType<typeof useAddSurveyHistoryEventMutation>;
export type AddSurveyHistoryEventMutationResult = Apollo.MutationResult<AddSurveyHistoryEventMutation>;
export type AddSurveyHistoryEventMutationOptions = Apollo.BaseMutationOptions<AddSurveyHistoryEventMutation, AddSurveyHistoryEventMutationVariables>;
export const CreateClientDocument = gql`
    mutation CreateClient($options: CreateClientInput!, $image: Upload) {
  createClient(options: $options, image: $image) {
    client {
      deletedDate
      id
      name
      imageUrl
    }
    error
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      options: // value for 'options'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const DeleteClientDocument = gql`
    mutation DeleteClient($options: DeleteClientInput!) {
  deleteClient(options: $options) {
    deletedDate
    id
  }
}
    `;
export type DeleteClientMutationFn = Apollo.MutationFunction<DeleteClientMutation, DeleteClientMutationVariables>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument, options);
      }
export type DeleteClientMutationHookResult = ReturnType<typeof useDeleteClientMutation>;
export type DeleteClientMutationResult = Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($options: UpdateClientInput!, $image: Upload) {
  updateClient(options: $options, image: $image) {
    deletedDate
    id
    name
    imageUrl
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      options: // value for 'options'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const SaveTargetCultureClientDocument = gql`
    mutation saveTargetCultureClient($options: TargetCultureInput!) {
  saveTargetCultureClient(options: $options) {
    primaryArchetypeId
    secondaryArchetypeId
    behaviours {
      id
    }
  }
}
    `;
export type SaveTargetCultureClientMutationFn = Apollo.MutationFunction<SaveTargetCultureClientMutation, SaveTargetCultureClientMutationVariables>;

/**
 * __useSaveTargetCultureClientMutation__
 *
 * To run a mutation, you first call `useSaveTargetCultureClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTargetCultureClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTargetCultureClientMutation, { data, loading, error }] = useSaveTargetCultureClientMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSaveTargetCultureClientMutation(baseOptions?: Apollo.MutationHookOptions<SaveTargetCultureClientMutation, SaveTargetCultureClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTargetCultureClientMutation, SaveTargetCultureClientMutationVariables>(SaveTargetCultureClientDocument, options);
      }
export type SaveTargetCultureClientMutationHookResult = ReturnType<typeof useSaveTargetCultureClientMutation>;
export type SaveTargetCultureClientMutationResult = Apollo.MutationResult<SaveTargetCultureClientMutation>;
export type SaveTargetCultureClientMutationOptions = Apollo.BaseMutationOptions<SaveTargetCultureClientMutation, SaveTargetCultureClientMutationVariables>;
export const AddConsultantsDocument = gql`
    mutation AddConsultants($options: AddConsultantsInput!) {
  addConsultants(options: $options) {
    id
    firstName
    lastName
    email
  }
}
    `;
export type AddConsultantsMutationFn = Apollo.MutationFunction<AddConsultantsMutation, AddConsultantsMutationVariables>;

/**
 * __useAddConsultantsMutation__
 *
 * To run a mutation, you first call `useAddConsultantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConsultantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConsultantsMutation, { data, loading, error }] = useAddConsultantsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAddConsultantsMutation(baseOptions?: Apollo.MutationHookOptions<AddConsultantsMutation, AddConsultantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConsultantsMutation, AddConsultantsMutationVariables>(AddConsultantsDocument, options);
      }
export type AddConsultantsMutationHookResult = ReturnType<typeof useAddConsultantsMutation>;
export type AddConsultantsMutationResult = Apollo.MutationResult<AddConsultantsMutation>;
export type AddConsultantsMutationOptions = Apollo.BaseMutationOptions<AddConsultantsMutation, AddConsultantsMutationVariables>;
export const DeleteConsultantDocument = gql`
    mutation DeleteConsultant($options: DeleteConsultantInput!) {
  deleteConsultant(options: $options)
}
    `;
export type DeleteConsultantMutationFn = Apollo.MutationFunction<DeleteConsultantMutation, DeleteConsultantMutationVariables>;

/**
 * __useDeleteConsultantMutation__
 *
 * To run a mutation, you first call `useDeleteConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsultantMutation, { data, loading, error }] = useDeleteConsultantMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteConsultantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsultantMutation, DeleteConsultantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsultantMutation, DeleteConsultantMutationVariables>(DeleteConsultantDocument, options);
      }
export type DeleteConsultantMutationHookResult = ReturnType<typeof useDeleteConsultantMutation>;
export type DeleteConsultantMutationResult = Apollo.MutationResult<DeleteConsultantMutation>;
export type DeleteConsultantMutationOptions = Apollo.BaseMutationOptions<DeleteConsultantMutation, DeleteConsultantMutationVariables>;
export const AssignClientsDocument = gql`
    mutation AssignClients($options: AssignClientsInput!) {
  assignClients(options: $options)
}
    `;
export type AssignClientsMutationFn = Apollo.MutationFunction<AssignClientsMutation, AssignClientsMutationVariables>;

/**
 * __useAssignClientsMutation__
 *
 * To run a mutation, you first call `useAssignClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignClientsMutation, { data, loading, error }] = useAssignClientsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAssignClientsMutation(baseOptions?: Apollo.MutationHookOptions<AssignClientsMutation, AssignClientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignClientsMutation, AssignClientsMutationVariables>(AssignClientsDocument, options);
      }
export type AssignClientsMutationHookResult = ReturnType<typeof useAssignClientsMutation>;
export type AssignClientsMutationResult = Apollo.MutationResult<AssignClientsMutation>;
export type AssignClientsMutationOptions = Apollo.BaseMutationOptions<AssignClientsMutation, AssignClientsMutationVariables>;
export const ChangeEmailConfigDocument = gql`
    mutation ChangeEmailConfig($options: ChangeEmailConfigInput!) {
  changeEmailConfig(options: $options)
}
    `;
export type ChangeEmailConfigMutationFn = Apollo.MutationFunction<ChangeEmailConfigMutation, ChangeEmailConfigMutationVariables>;

/**
 * __useChangeEmailConfigMutation__
 *
 * To run a mutation, you first call `useChangeEmailConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailConfigMutation, { data, loading, error }] = useChangeEmailConfigMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useChangeEmailConfigMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailConfigMutation, ChangeEmailConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEmailConfigMutation, ChangeEmailConfigMutationVariables>(ChangeEmailConfigDocument, options);
      }
export type ChangeEmailConfigMutationHookResult = ReturnType<typeof useChangeEmailConfigMutation>;
export type ChangeEmailConfigMutationResult = Apollo.MutationResult<ChangeEmailConfigMutation>;
export type ChangeEmailConfigMutationOptions = Apollo.BaseMutationOptions<ChangeEmailConfigMutation, ChangeEmailConfigMutationVariables>;
export const UpdateClientEmailConfigDocument = gql`
    mutation UpdateClientEmailConfig($options: UpdateClientConfigInput!) {
  updateClientEmailConfig(options: $options) {
    emailConfig {
      type
      subject
      body
    }
  }
}
    `;
export type UpdateClientEmailConfigMutationFn = Apollo.MutationFunction<UpdateClientEmailConfigMutation, UpdateClientEmailConfigMutationVariables>;

/**
 * __useUpdateClientEmailConfigMutation__
 *
 * To run a mutation, you first call `useUpdateClientEmailConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientEmailConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientEmailConfigMutation, { data, loading, error }] = useUpdateClientEmailConfigMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateClientEmailConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientEmailConfigMutation, UpdateClientEmailConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientEmailConfigMutation, UpdateClientEmailConfigMutationVariables>(UpdateClientEmailConfigDocument, options);
      }
export type UpdateClientEmailConfigMutationHookResult = ReturnType<typeof useUpdateClientEmailConfigMutation>;
export type UpdateClientEmailConfigMutationResult = Apollo.MutationResult<UpdateClientEmailConfigMutation>;
export type UpdateClientEmailConfigMutationOptions = Apollo.BaseMutationOptions<UpdateClientEmailConfigMutation, UpdateClientEmailConfigMutationVariables>;
export const SaveGroupReportParticipantDocument = gql`
    mutation SaveGroupReportParticipant($groupId: String!, $participantId: String!, $surveyId: String!, $pdfLanguage: String!) {
  saveGroupReportParticipant(
    groupId: $groupId
    participantId: $participantId
    surveyId: $surveyId
    pdfLanguage: $pdfLanguage
  ) {
    id
    participantId
    groupReportId
    language
  }
}
    `;
export type SaveGroupReportParticipantMutationFn = Apollo.MutationFunction<SaveGroupReportParticipantMutation, SaveGroupReportParticipantMutationVariables>;

/**
 * __useSaveGroupReportParticipantMutation__
 *
 * To run a mutation, you first call `useSaveGroupReportParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGroupReportParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGroupReportParticipantMutation, { data, loading, error }] = useSaveGroupReportParticipantMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *      pdfLanguage: // value for 'pdfLanguage'
 *   },
 * });
 */
export function useSaveGroupReportParticipantMutation(baseOptions?: Apollo.MutationHookOptions<SaveGroupReportParticipantMutation, SaveGroupReportParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveGroupReportParticipantMutation, SaveGroupReportParticipantMutationVariables>(SaveGroupReportParticipantDocument, options);
      }
export type SaveGroupReportParticipantMutationHookResult = ReturnType<typeof useSaveGroupReportParticipantMutation>;
export type SaveGroupReportParticipantMutationResult = Apollo.MutationResult<SaveGroupReportParticipantMutation>;
export type SaveGroupReportParticipantMutationOptions = Apollo.BaseMutationOptions<SaveGroupReportParticipantMutation, SaveGroupReportParticipantMutationVariables>;
export const SaveGroupReportPdfDocument = gql`
    mutation SaveGroupReportPdf($groupId: String!, $surveyId: String!, $pdfLanguage: String!) {
  saveGroupReportPdf(
    groupId: $groupId
    surveyId: $surveyId
    pdfLanguage: $pdfLanguage
  ) {
    id
    groupReportId
    language
  }
}
    `;
export type SaveGroupReportPdfMutationFn = Apollo.MutationFunction<SaveGroupReportPdfMutation, SaveGroupReportPdfMutationVariables>;

/**
 * __useSaveGroupReportPdfMutation__
 *
 * To run a mutation, you first call `useSaveGroupReportPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGroupReportPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGroupReportPdfMutation, { data, loading, error }] = useSaveGroupReportPdfMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      surveyId: // value for 'surveyId'
 *      pdfLanguage: // value for 'pdfLanguage'
 *   },
 * });
 */
export function useSaveGroupReportPdfMutation(baseOptions?: Apollo.MutationHookOptions<SaveGroupReportPdfMutation, SaveGroupReportPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveGroupReportPdfMutation, SaveGroupReportPdfMutationVariables>(SaveGroupReportPdfDocument, options);
      }
export type SaveGroupReportPdfMutationHookResult = ReturnType<typeof useSaveGroupReportPdfMutation>;
export type SaveGroupReportPdfMutationResult = Apollo.MutationResult<SaveGroupReportPdfMutation>;
export type SaveGroupReportPdfMutationOptions = Apollo.BaseMutationOptions<SaveGroupReportPdfMutation, SaveGroupReportPdfMutationVariables>;
export const CreateGroupReportDocument = gql`
    mutation CreateGroupReport($options: CreateGroupReportInput!) {
  createGroupReport(options: $options) {
    id
    clientId
    reportName
  }
}
    `;
export type CreateGroupReportMutationFn = Apollo.MutationFunction<CreateGroupReportMutation, CreateGroupReportMutationVariables>;

/**
 * __useCreateGroupReportMutation__
 *
 * To run a mutation, you first call `useCreateGroupReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupReportMutation, { data, loading, error }] = useCreateGroupReportMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateGroupReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupReportMutation, CreateGroupReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupReportMutation, CreateGroupReportMutationVariables>(CreateGroupReportDocument, options);
      }
export type CreateGroupReportMutationHookResult = ReturnType<typeof useCreateGroupReportMutation>;
export type CreateGroupReportMutationResult = Apollo.MutationResult<CreateGroupReportMutation>;
export type CreateGroupReportMutationOptions = Apollo.BaseMutationOptions<CreateGroupReportMutation, CreateGroupReportMutationVariables>;
export const DeleteGroupReportDocument = gql`
    mutation DeleteGroupReport($groupReportId: String!) {
  deleteGroupReport(groupReportId: $groupReportId)
}
    `;
export type DeleteGroupReportMutationFn = Apollo.MutationFunction<DeleteGroupReportMutation, DeleteGroupReportMutationVariables>;

/**
 * __useDeleteGroupReportMutation__
 *
 * To run a mutation, you first call `useDeleteGroupReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupReportMutation, { data, loading, error }] = useDeleteGroupReportMutation({
 *   variables: {
 *      groupReportId: // value for 'groupReportId'
 *   },
 * });
 */
export function useDeleteGroupReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupReportMutation, DeleteGroupReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupReportMutation, DeleteGroupReportMutationVariables>(DeleteGroupReportDocument, options);
      }
export type DeleteGroupReportMutationHookResult = ReturnType<typeof useDeleteGroupReportMutation>;
export type DeleteGroupReportMutationResult = Apollo.MutationResult<DeleteGroupReportMutation>;
export type DeleteGroupReportMutationOptions = Apollo.BaseMutationOptions<DeleteGroupReportMutation, DeleteGroupReportMutationVariables>;
export const AddParticipantsDocument = gql`
    mutation AddParticipants($options: AddParticipantsInput!) {
  addParticipants(options: $options)
}
    `;
export type AddParticipantsMutationFn = Apollo.MutationFunction<AddParticipantsMutation, AddParticipantsMutationVariables>;

/**
 * __useAddParticipantsMutation__
 *
 * To run a mutation, you first call `useAddParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantsMutation, { data, loading, error }] = useAddParticipantsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAddParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<AddParticipantsMutation, AddParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddParticipantsMutation, AddParticipantsMutationVariables>(AddParticipantsDocument, options);
      }
export type AddParticipantsMutationHookResult = ReturnType<typeof useAddParticipantsMutation>;
export type AddParticipantsMutationResult = Apollo.MutationResult<AddParticipantsMutation>;
export type AddParticipantsMutationOptions = Apollo.BaseMutationOptions<AddParticipantsMutation, AddParticipantsMutationVariables>;
export const AssignParticipantsDocument = gql`
    mutation AssignParticipants($options: AssignParticipantsInput!) {
  AssignParticipants(options: $options)
}
    `;
export type AssignParticipantsMutationFn = Apollo.MutationFunction<AssignParticipantsMutation, AssignParticipantsMutationVariables>;

/**
 * __useAssignParticipantsMutation__
 *
 * To run a mutation, you first call `useAssignParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignParticipantsMutation, { data, loading, error }] = useAssignParticipantsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAssignParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<AssignParticipantsMutation, AssignParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignParticipantsMutation, AssignParticipantsMutationVariables>(AssignParticipantsDocument, options);
      }
export type AssignParticipantsMutationHookResult = ReturnType<typeof useAssignParticipantsMutation>;
export type AssignParticipantsMutationResult = Apollo.MutationResult<AssignParticipantsMutation>;
export type AssignParticipantsMutationOptions = Apollo.BaseMutationOptions<AssignParticipantsMutation, AssignParticipantsMutationVariables>;
export const DeleteParticipantDocument = gql`
    mutation DeleteParticipant($participantId: String!, $projectId: String!) {
  deleteParticipant(participantId: $participantId, projectId: $projectId)
}
    `;
export type DeleteParticipantMutationFn = Apollo.MutationFunction<DeleteParticipantMutation, DeleteParticipantMutationVariables>;

/**
 * __useDeleteParticipantMutation__
 *
 * To run a mutation, you first call `useDeleteParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParticipantMutation, { data, loading, error }] = useDeleteParticipantMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteParticipantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteParticipantMutation, DeleteParticipantMutationVariables>(DeleteParticipantDocument, options);
      }
export type DeleteParticipantMutationHookResult = ReturnType<typeof useDeleteParticipantMutation>;
export type DeleteParticipantMutationResult = Apollo.MutationResult<DeleteParticipantMutation>;
export type DeleteParticipantMutationOptions = Apollo.BaseMutationOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>;
export const CopyOrMoveParticipantsDocument = gql`
    mutation CopyOrMoveParticipants($options: CopyOrMoveParticipantsInput!) {
  copyOrMoveParticipants(options: $options)
}
    `;
export type CopyOrMoveParticipantsMutationFn = Apollo.MutationFunction<CopyOrMoveParticipantsMutation, CopyOrMoveParticipantsMutationVariables>;

/**
 * __useCopyOrMoveParticipantsMutation__
 *
 * To run a mutation, you first call `useCopyOrMoveParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyOrMoveParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyOrMoveParticipantsMutation, { data, loading, error }] = useCopyOrMoveParticipantsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCopyOrMoveParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<CopyOrMoveParticipantsMutation, CopyOrMoveParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyOrMoveParticipantsMutation, CopyOrMoveParticipantsMutationVariables>(CopyOrMoveParticipantsDocument, options);
      }
export type CopyOrMoveParticipantsMutationHookResult = ReturnType<typeof useCopyOrMoveParticipantsMutation>;
export type CopyOrMoveParticipantsMutationResult = Apollo.MutationResult<CopyOrMoveParticipantsMutation>;
export type CopyOrMoveParticipantsMutationOptions = Apollo.BaseMutationOptions<CopyOrMoveParticipantsMutation, CopyOrMoveParticipantsMutationVariables>;
export const SendInviteNotificationToParticipantDocument = gql`
    mutation SendInviteNotificationToParticipant($participantId: String!, $surveyId: String!, $clientId: String!) {
  sendInviteNotificationToParticipant(
    participantId: $participantId
    surveyId: $surveyId
    clientId: $clientId
  )
}
    `;
export type SendInviteNotificationToParticipantMutationFn = Apollo.MutationFunction<SendInviteNotificationToParticipantMutation, SendInviteNotificationToParticipantMutationVariables>;

/**
 * __useSendInviteNotificationToParticipantMutation__
 *
 * To run a mutation, you first call `useSendInviteNotificationToParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteNotificationToParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteNotificationToParticipantMutation, { data, loading, error }] = useSendInviteNotificationToParticipantMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSendInviteNotificationToParticipantMutation(baseOptions?: Apollo.MutationHookOptions<SendInviteNotificationToParticipantMutation, SendInviteNotificationToParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInviteNotificationToParticipantMutation, SendInviteNotificationToParticipantMutationVariables>(SendInviteNotificationToParticipantDocument, options);
      }
export type SendInviteNotificationToParticipantMutationHookResult = ReturnType<typeof useSendInviteNotificationToParticipantMutation>;
export type SendInviteNotificationToParticipantMutationResult = Apollo.MutationResult<SendInviteNotificationToParticipantMutation>;
export type SendInviteNotificationToParticipantMutationOptions = Apollo.BaseMutationOptions<SendInviteNotificationToParticipantMutation, SendInviteNotificationToParticipantMutationVariables>;
export const SendReminderToParticipantDocument = gql`
    mutation SendReminderToParticipant($participantId: String!, $surveyId: String!, $clientId: String!) {
  sendReminderToParticipant(
    participantId: $participantId
    surveyId: $surveyId
    clientId: $clientId
  )
}
    `;
export type SendReminderToParticipantMutationFn = Apollo.MutationFunction<SendReminderToParticipantMutation, SendReminderToParticipantMutationVariables>;

/**
 * __useSendReminderToParticipantMutation__
 *
 * To run a mutation, you first call `useSendReminderToParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReminderToParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReminderToParticipantMutation, { data, loading, error }] = useSendReminderToParticipantMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSendReminderToParticipantMutation(baseOptions?: Apollo.MutationHookOptions<SendReminderToParticipantMutation, SendReminderToParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReminderToParticipantMutation, SendReminderToParticipantMutationVariables>(SendReminderToParticipantDocument, options);
      }
export type SendReminderToParticipantMutationHookResult = ReturnType<typeof useSendReminderToParticipantMutation>;
export type SendReminderToParticipantMutationResult = Apollo.MutationResult<SendReminderToParticipantMutation>;
export type SendReminderToParticipantMutationOptions = Apollo.BaseMutationOptions<SendReminderToParticipantMutation, SendReminderToParticipantMutationVariables>;
export const CompleteSurveyDocument = gql`
    mutation completeSurvey($surveyId: String!, $participantId: String!) {
  completeSurvey(surveyId: $surveyId, participantId: $participantId) {
    message
  }
}
    `;
export type CompleteSurveyMutationFn = Apollo.MutationFunction<CompleteSurveyMutation, CompleteSurveyMutationVariables>;

/**
 * __useCompleteSurveyMutation__
 *
 * To run a mutation, you first call `useCompleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSurveyMutation, { data, loading, error }] = useCompleteSurveyMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useCompleteSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CompleteSurveyMutation, CompleteSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteSurveyMutation, CompleteSurveyMutationVariables>(CompleteSurveyDocument, options);
      }
export type CompleteSurveyMutationHookResult = ReturnType<typeof useCompleteSurveyMutation>;
export type CompleteSurveyMutationResult = Apollo.MutationResult<CompleteSurveyMutation>;
export type CompleteSurveyMutationOptions = Apollo.BaseMutationOptions<CompleteSurveyMutation, CompleteSurveyMutationVariables>;
export const SaveClientForParticipantDocument = gql`
    mutation SaveClientForParticipant($options: ClientForParticipantInput!) {
  saveClientForParticipant(options: $options) {
    clients {
      id
      name
    }
  }
}
    `;
export type SaveClientForParticipantMutationFn = Apollo.MutationFunction<SaveClientForParticipantMutation, SaveClientForParticipantMutationVariables>;

/**
 * __useSaveClientForParticipantMutation__
 *
 * To run a mutation, you first call `useSaveClientForParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClientForParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClientForParticipantMutation, { data, loading, error }] = useSaveClientForParticipantMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSaveClientForParticipantMutation(baseOptions?: Apollo.MutationHookOptions<SaveClientForParticipantMutation, SaveClientForParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveClientForParticipantMutation, SaveClientForParticipantMutationVariables>(SaveClientForParticipantDocument, options);
      }
export type SaveClientForParticipantMutationHookResult = ReturnType<typeof useSaveClientForParticipantMutation>;
export type SaveClientForParticipantMutationResult = Apollo.MutationResult<SaveClientForParticipantMutation>;
export type SaveClientForParticipantMutationOptions = Apollo.BaseMutationOptions<SaveClientForParticipantMutation, SaveClientForParticipantMutationVariables>;
export const SaveServiceTypeForParticipantDocument = gql`
    mutation SaveServiceTypeForParticipant($options: ServiceTypeInput!) {
  saveServiceTypeForParticipant(options: $options) {
    serviceTypes {
      label
      value
    }
  }
}
    `;
export type SaveServiceTypeForParticipantMutationFn = Apollo.MutationFunction<SaveServiceTypeForParticipantMutation, SaveServiceTypeForParticipantMutationVariables>;

/**
 * __useSaveServiceTypeForParticipantMutation__
 *
 * To run a mutation, you first call `useSaveServiceTypeForParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveServiceTypeForParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveServiceTypeForParticipantMutation, { data, loading, error }] = useSaveServiceTypeForParticipantMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSaveServiceTypeForParticipantMutation(baseOptions?: Apollo.MutationHookOptions<SaveServiceTypeForParticipantMutation, SaveServiceTypeForParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveServiceTypeForParticipantMutation, SaveServiceTypeForParticipantMutationVariables>(SaveServiceTypeForParticipantDocument, options);
      }
export type SaveServiceTypeForParticipantMutationHookResult = ReturnType<typeof useSaveServiceTypeForParticipantMutation>;
export type SaveServiceTypeForParticipantMutationResult = Apollo.MutationResult<SaveServiceTypeForParticipantMutation>;
export type SaveServiceTypeForParticipantMutationOptions = Apollo.BaseMutationOptions<SaveServiceTypeForParticipantMutation, SaveServiceTypeForParticipantMutationVariables>;
export const SaveAnswerDocument = gql`
    mutation SaveAnswer($options: CreateSurveyAnswerInput!) {
  saveAnswer(options: $options) {
    id
    responses {
      questionId
      answer {
        optionId
        order
      }
    }
  }
}
    `;
export type SaveAnswerMutationFn = Apollo.MutationFunction<SaveAnswerMutation, SaveAnswerMutationVariables>;

/**
 * __useSaveAnswerMutation__
 *
 * To run a mutation, you first call `useSaveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAnswerMutation, { data, loading, error }] = useSaveAnswerMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSaveAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SaveAnswerMutation, SaveAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAnswerMutation, SaveAnswerMutationVariables>(SaveAnswerDocument, options);
      }
export type SaveAnswerMutationHookResult = ReturnType<typeof useSaveAnswerMutation>;
export type SaveAnswerMutationResult = Apollo.MutationResult<SaveAnswerMutation>;
export type SaveAnswerMutationOptions = Apollo.BaseMutationOptions<SaveAnswerMutation, SaveAnswerMutationVariables>;
export const SaveDemographicsDocument = gql`
    mutation SaveDemographics($options: UpdateParticipantDemographicInput!) {
  saveDemographics(options: $options) {
    id
  }
}
    `;
export type SaveDemographicsMutationFn = Apollo.MutationFunction<SaveDemographicsMutation, SaveDemographicsMutationVariables>;

/**
 * __useSaveDemographicsMutation__
 *
 * To run a mutation, you first call `useSaveDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDemographicsMutation, { data, loading, error }] = useSaveDemographicsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSaveDemographicsMutation(baseOptions?: Apollo.MutationHookOptions<SaveDemographicsMutation, SaveDemographicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDemographicsMutation, SaveDemographicsMutationVariables>(SaveDemographicsDocument, options);
      }
export type SaveDemographicsMutationHookResult = ReturnType<typeof useSaveDemographicsMutation>;
export type SaveDemographicsMutationResult = Apollo.MutationResult<SaveDemographicsMutation>;
export type SaveDemographicsMutationOptions = Apollo.BaseMutationOptions<SaveDemographicsMutation, SaveDemographicsMutationVariables>;
export const AddParticipantEventHistoryDocument = gql`
    mutation AddParticipantEventHistory($options: AddParticipantHistoryEventInput!) {
  addParticipantEventHistory(options: $options) {
    id
    surveyId
    type
    data
  }
}
    `;
export type AddParticipantEventHistoryMutationFn = Apollo.MutationFunction<AddParticipantEventHistoryMutation, AddParticipantEventHistoryMutationVariables>;

/**
 * __useAddParticipantEventHistoryMutation__
 *
 * To run a mutation, you first call `useAddParticipantEventHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantEventHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantEventHistoryMutation, { data, loading, error }] = useAddParticipantEventHistoryMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAddParticipantEventHistoryMutation(baseOptions?: Apollo.MutationHookOptions<AddParticipantEventHistoryMutation, AddParticipantEventHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddParticipantEventHistoryMutation, AddParticipantEventHistoryMutationVariables>(AddParticipantEventHistoryDocument, options);
      }
export type AddParticipantEventHistoryMutationHookResult = ReturnType<typeof useAddParticipantEventHistoryMutation>;
export type AddParticipantEventHistoryMutationResult = Apollo.MutationResult<AddParticipantEventHistoryMutation>;
export type AddParticipantEventHistoryMutationOptions = Apollo.BaseMutationOptions<AddParticipantEventHistoryMutation, AddParticipantEventHistoryMutationVariables>;
export const ImportParticipantsDocument = gql`
    mutation ImportParticipants($surveyId: String!, $projectId: String!, $clientId: String!, $input: ImportParticipantInput!) {
  importParticipants(
    surveyId: $surveyId
    projectId: $projectId
    clientId: $clientId
    input: $input
  ) {
    importId
    status
    rows
  }
}
    `;
export type ImportParticipantsMutationFn = Apollo.MutationFunction<ImportParticipantsMutation, ImportParticipantsMutationVariables>;

/**
 * __useImportParticipantsMutation__
 *
 * To run a mutation, you first call `useImportParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importParticipantsMutation, { data, loading, error }] = useImportParticipantsMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      projectId: // value for 'projectId'
 *      clientId: // value for 'clientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<ImportParticipantsMutation, ImportParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportParticipantsMutation, ImportParticipantsMutationVariables>(ImportParticipantsDocument, options);
      }
export type ImportParticipantsMutationHookResult = ReturnType<typeof useImportParticipantsMutation>;
export type ImportParticipantsMutationResult = Apollo.MutationResult<ImportParticipantsMutation>;
export type ImportParticipantsMutationOptions = Apollo.BaseMutationOptions<ImportParticipantsMutation, ImportParticipantsMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($options: CreateProjectInput!) {
  createProject(options: $options) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectNameDocument = gql`
    mutation UpdateProjectName($options: UpdateProjectInput!) {
  updateProjectName(options: $options) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export type UpdateProjectNameMutationFn = Apollo.MutationFunction<UpdateProjectNameMutation, UpdateProjectNameMutationVariables>;

/**
 * __useUpdateProjectNameMutation__
 *
 * To run a mutation, you first call `useUpdateProjectNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectNameMutation, { data, loading, error }] = useUpdateProjectNameMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateProjectNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectNameMutation, UpdateProjectNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectNameMutation, UpdateProjectNameMutationVariables>(UpdateProjectNameDocument, options);
      }
export type UpdateProjectNameMutationHookResult = ReturnType<typeof useUpdateProjectNameMutation>;
export type UpdateProjectNameMutationResult = Apollo.MutationResult<UpdateProjectNameMutation>;
export type UpdateProjectNameMutationOptions = Apollo.BaseMutationOptions<UpdateProjectNameMutation, UpdateProjectNameMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($options: DeleteProjectInput!) {
  deleteProject(options: $options)
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const AssignProjectsDocument = gql`
    mutation AssignProjects($options: AssignProjectsInput!) {
  assignProjects(options: $options)
}
    `;
export type AssignProjectsMutationFn = Apollo.MutationFunction<AssignProjectsMutation, AssignProjectsMutationVariables>;

/**
 * __useAssignProjectsMutation__
 *
 * To run a mutation, you first call `useAssignProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProjectsMutation, { data, loading, error }] = useAssignProjectsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAssignProjectsMutation(baseOptions?: Apollo.MutationHookOptions<AssignProjectsMutation, AssignProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignProjectsMutation, AssignProjectsMutationVariables>(AssignProjectsDocument, options);
      }
export type AssignProjectsMutationHookResult = ReturnType<typeof useAssignProjectsMutation>;
export type AssignProjectsMutationResult = Apollo.MutationResult<AssignProjectsMutation>;
export type AssignProjectsMutationOptions = Apollo.BaseMutationOptions<AssignProjectsMutation, AssignProjectsMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($options: RegisterInput!) {
  register(options: $options) {
    error
    idToken
    user {
      firstName
      lastName
      firebaseId
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const SetPasswordDocument = gql`
    mutation SetPassword($options: SetPasswordInput!) {
  setPassword(options: $options) {
    error
  }
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const SetPasswordForInvitedUserDocument = gql`
    mutation SetPasswordForInvitedUser($options: SetPasswordInput!) {
  setPasswordForInvitedUser(options: $options)
}
    `;
export type SetPasswordForInvitedUserMutationFn = Apollo.MutationFunction<SetPasswordForInvitedUserMutation, SetPasswordForInvitedUserMutationVariables>;

/**
 * __useSetPasswordForInvitedUserMutation__
 *
 * To run a mutation, you first call `useSetPasswordForInvitedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordForInvitedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordForInvitedUserMutation, { data, loading, error }] = useSetPasswordForInvitedUserMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSetPasswordForInvitedUserMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordForInvitedUserMutation, SetPasswordForInvitedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordForInvitedUserMutation, SetPasswordForInvitedUserMutationVariables>(SetPasswordForInvitedUserDocument, options);
      }
export type SetPasswordForInvitedUserMutationHookResult = ReturnType<typeof useSetPasswordForInvitedUserMutation>;
export type SetPasswordForInvitedUserMutationResult = Apollo.MutationResult<SetPasswordForInvitedUserMutation>;
export type SetPasswordForInvitedUserMutationOptions = Apollo.BaseMutationOptions<SetPasswordForInvitedUserMutation, SetPasswordForInvitedUserMutationVariables>;
export const CreateResponseReportDocument = gql`
    mutation CreateResponseReport($participantId: String!, $surveyId: String!) {
  createResponseReport(participantId: $participantId, surveyId: $surveyId)
}
    `;
export type CreateResponseReportMutationFn = Apollo.MutationFunction<CreateResponseReportMutation, CreateResponseReportMutationVariables>;

/**
 * __useCreateResponseReportMutation__
 *
 * To run a mutation, you first call `useCreateResponseReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResponseReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResponseReportMutation, { data, loading, error }] = useCreateResponseReportMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useCreateResponseReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateResponseReportMutation, CreateResponseReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResponseReportMutation, CreateResponseReportMutationVariables>(CreateResponseReportDocument, options);
      }
export type CreateResponseReportMutationHookResult = ReturnType<typeof useCreateResponseReportMutation>;
export type CreateResponseReportMutationResult = Apollo.MutationResult<CreateResponseReportMutation>;
export type CreateResponseReportMutationOptions = Apollo.BaseMutationOptions<CreateResponseReportMutation, CreateResponseReportMutationVariables>;
export const CreateTeamReportDocument = gql`
    mutation CreateTeamReport($options: CreateTeamReportInput!) {
  createTeamReport(options: $options) {
    id
    reportName
  }
}
    `;
export type CreateTeamReportMutationFn = Apollo.MutationFunction<CreateTeamReportMutation, CreateTeamReportMutationVariables>;

/**
 * __useCreateTeamReportMutation__
 *
 * To run a mutation, you first call `useCreateTeamReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamReportMutation, { data, loading, error }] = useCreateTeamReportMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateTeamReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamReportMutation, CreateTeamReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamReportMutation, CreateTeamReportMutationVariables>(CreateTeamReportDocument, options);
      }
export type CreateTeamReportMutationHookResult = ReturnType<typeof useCreateTeamReportMutation>;
export type CreateTeamReportMutationResult = Apollo.MutationResult<CreateTeamReportMutation>;
export type CreateTeamReportMutationOptions = Apollo.BaseMutationOptions<CreateTeamReportMutation, CreateTeamReportMutationVariables>;
export const DeleteTeamReportDocument = gql`
    mutation DeleteTeamReport($teamReportId: String!) {
  deleteTeamReport(teamReportId: $teamReportId)
}
    `;
export type DeleteTeamReportMutationFn = Apollo.MutationFunction<DeleteTeamReportMutation, DeleteTeamReportMutationVariables>;

/**
 * __useDeleteTeamReportMutation__
 *
 * To run a mutation, you first call `useDeleteTeamReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamReportMutation, { data, loading, error }] = useDeleteTeamReportMutation({
 *   variables: {
 *      teamReportId: // value for 'teamReportId'
 *   },
 * });
 */
export function useDeleteTeamReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamReportMutation, DeleteTeamReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamReportMutation, DeleteTeamReportMutationVariables>(DeleteTeamReportDocument, options);
      }
export type DeleteTeamReportMutationHookResult = ReturnType<typeof useDeleteTeamReportMutation>;
export type DeleteTeamReportMutationResult = Apollo.MutationResult<DeleteTeamReportMutation>;
export type DeleteTeamReportMutationOptions = Apollo.BaseMutationOptions<DeleteTeamReportMutation, DeleteTeamReportMutationVariables>;
export const CreateTeamReportPdfDocument = gql`
    mutation CreateTeamReportPdf($teamReportId: String!, $surveyId: String!, $pdfLanguage: String!) {
  createTeamReportPdf(
    teamReportId: $teamReportId
    surveyId: $surveyId
    pdfLanguage: $pdfLanguage
  ) {
    id
    teamReportId
    language
    downloadUrl
  }
}
    `;
export type CreateTeamReportPdfMutationFn = Apollo.MutationFunction<CreateTeamReportPdfMutation, CreateTeamReportPdfMutationVariables>;

/**
 * __useCreateTeamReportPdfMutation__
 *
 * To run a mutation, you first call `useCreateTeamReportPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamReportPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamReportPdfMutation, { data, loading, error }] = useCreateTeamReportPdfMutation({
 *   variables: {
 *      teamReportId: // value for 'teamReportId'
 *      surveyId: // value for 'surveyId'
 *      pdfLanguage: // value for 'pdfLanguage'
 *   },
 * });
 */
export function useCreateTeamReportPdfMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamReportPdfMutation, CreateTeamReportPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamReportPdfMutation, CreateTeamReportPdfMutationVariables>(CreateTeamReportPdfDocument, options);
      }
export type CreateTeamReportPdfMutationHookResult = ReturnType<typeof useCreateTeamReportPdfMutation>;
export type CreateTeamReportPdfMutationResult = Apollo.MutationResult<CreateTeamReportPdfMutation>;
export type CreateTeamReportPdfMutationOptions = Apollo.BaseMutationOptions<CreateTeamReportPdfMutation, CreateTeamReportPdfMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($newPassword: String!, $token: String!) {
  resetPassword(newPassword: $newPassword, token: $token)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ConsultantsDocument = gql`
    query Consultants($options: PagedQueryInput) {
  consultants(options: $options) {
    results {
      id
      email
      firstName
      lastName
    }
    pageOffset
    totalCount
  }
}
    `;

/**
 * __useConsultantsQuery__
 *
 * To run a query within a React component, call `useConsultantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useConsultantsQuery(baseOptions?: Apollo.QueryHookOptions<ConsultantsQuery, ConsultantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantsQuery, ConsultantsQueryVariables>(ConsultantsDocument, options);
      }
export function useConsultantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantsQuery, ConsultantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantsQuery, ConsultantsQueryVariables>(ConsultantsDocument, options);
        }
export type ConsultantsQueryHookResult = ReturnType<typeof useConsultantsQuery>;
export type ConsultantsLazyQueryHookResult = ReturnType<typeof useConsultantsLazyQuery>;
export type ConsultantsQueryResult = Apollo.QueryResult<ConsultantsQuery, ConsultantsQueryVariables>;
export const GetArchetypesDocument = gql`
    query GetArchetypes {
  getArchetypes {
    id
    name
    priority
    behaviours {
      id
      name
      priority
      interviewQuestions
    }
  }
}
    `;

/**
 * __useGetArchetypesQuery__
 *
 * To run a query within a React component, call `useGetArchetypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchetypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchetypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArchetypesQuery(baseOptions?: Apollo.QueryHookOptions<GetArchetypesQuery, GetArchetypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArchetypesQuery, GetArchetypesQueryVariables>(GetArchetypesDocument, options);
      }
export function useGetArchetypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchetypesQuery, GetArchetypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArchetypesQuery, GetArchetypesQueryVariables>(GetArchetypesDocument, options);
        }
export type GetArchetypesQueryHookResult = ReturnType<typeof useGetArchetypesQuery>;
export type GetArchetypesLazyQueryHookResult = ReturnType<typeof useGetArchetypesLazyQuery>;
export type GetArchetypesQueryResult = Apollo.QueryResult<GetArchetypesQuery, GetArchetypesQueryVariables>;
export const GetArchetypesWithTokenDocument = gql`
    query GetArchetypesWithToken($token: String!) {
  getArchetypesWithToken(token: $token) {
    id
    name
    priority
    behaviours {
      id
      name
      priority
      interviewQuestions
    }
  }
}
    `;

/**
 * __useGetArchetypesWithTokenQuery__
 *
 * To run a query within a React component, call `useGetArchetypesWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchetypesWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchetypesWithTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetArchetypesWithTokenQuery(baseOptions: Apollo.QueryHookOptions<GetArchetypesWithTokenQuery, GetArchetypesWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArchetypesWithTokenQuery, GetArchetypesWithTokenQueryVariables>(GetArchetypesWithTokenDocument, options);
      }
export function useGetArchetypesWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchetypesWithTokenQuery, GetArchetypesWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArchetypesWithTokenQuery, GetArchetypesWithTokenQueryVariables>(GetArchetypesWithTokenDocument, options);
        }
export type GetArchetypesWithTokenQueryHookResult = ReturnType<typeof useGetArchetypesWithTokenQuery>;
export type GetArchetypesWithTokenLazyQueryHookResult = ReturnType<typeof useGetArchetypesWithTokenLazyQuery>;
export type GetArchetypesWithTokenQueryResult = Apollo.QueryResult<GetArchetypesWithTokenQuery, GetArchetypesWithTokenQueryVariables>;
export const ClientsDocument = gql`
    query Clients($options: GetClientsQueryInput, $sort: SortInput, $searchTerm: String, $includeDeleted: Boolean) {
  clients(
    options: $options
    sort: $sort
    searchTerm: $searchTerm
    includeDeleted: $includeDeleted
  ) {
    results {
      id
      imageUrl
      name
      createdAt
      deletedDate
      userClients {
        id
        clientId
      }
      projects {
        id
        name
      }
    }
    pageSize
    pageOffset
    totalCount
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      sort: // value for 'sort'
 *      searchTerm: // value for 'searchTerm'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const ClientDocument = gql`
    query Client($options: QueryClientInput!) {
  client(options: $options) {
    client {
      deletedDate
      id
      name
      imageUrl
      primaryArchetypeId
      secondaryArchetypeId
      isConfigEnabled
      emailConfig {
        type
        subject
        body
        linkText
      }
      behaviours {
        id
        archetypeId
        name
      }
      groupReports {
        id
      }
    }
  }
}
    `;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useClientQuery(baseOptions: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const ClientForParticipantDocument = gql`
    query ClientForParticipant($options: QueryClientInput!) {
  clientForParticipant(options: $options) {
    imageUrl
    name
  }
}
    `;

/**
 * __useClientForParticipantQuery__
 *
 * To run a query within a React component, call `useClientForParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientForParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientForParticipantQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useClientForParticipantQuery(baseOptions: Apollo.QueryHookOptions<ClientForParticipantQuery, ClientForParticipantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientForParticipantQuery, ClientForParticipantQueryVariables>(ClientForParticipantDocument, options);
      }
export function useClientForParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientForParticipantQuery, ClientForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientForParticipantQuery, ClientForParticipantQueryVariables>(ClientForParticipantDocument, options);
        }
export type ClientForParticipantQueryHookResult = ReturnType<typeof useClientForParticipantQuery>;
export type ClientForParticipantLazyQueryHookResult = ReturnType<typeof useClientForParticipantLazyQuery>;
export type ClientForParticipantQueryResult = Apollo.QueryResult<ClientForParticipantQuery, ClientForParticipantQueryVariables>;
export const GetGroupReportDocument = gql`
    query GetGroupReport($groupId: String!, $surveyId: String!) {
  getGroupReport(groupId: $groupId, surveyId: $surveyId) {
    id
    reportName
    clientId
    client {
      id
      name
      imageUrl
    }
    groupReportParticipants {
      id
      participantId
      participant {
        demographics {
          id
          title
          value
        }
      }
      reportPdfGcsName
    }
    behaviours {
      id
    }
    primaryArchetypeId
    secondaryArchetypeId
  }
}
    `;

/**
 * __useGetGroupReportQuery__
 *
 * To run a query within a React component, call `useGetGroupReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupReportQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetGroupReportQuery(baseOptions: Apollo.QueryHookOptions<GetGroupReportQuery, GetGroupReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupReportQuery, GetGroupReportQueryVariables>(GetGroupReportDocument, options);
      }
export function useGetGroupReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupReportQuery, GetGroupReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupReportQuery, GetGroupReportQueryVariables>(GetGroupReportDocument, options);
        }
export type GetGroupReportQueryHookResult = ReturnType<typeof useGetGroupReportQuery>;
export type GetGroupReportLazyQueryHookResult = ReturnType<typeof useGetGroupReportLazyQuery>;
export type GetGroupReportQueryResult = Apollo.QueryResult<GetGroupReportQuery, GetGroupReportQueryVariables>;
export const GetGroupReportsDocument = gql`
    query GetGroupReports($options: GetGroupReportsInput!) {
  getGroupReports(options: $options) {
    pageOffset
    pageSize
    results {
      id
      reportName
      clientId
      surveyId
      createdAt
      updatedAt
      client {
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetGroupReportsQuery__
 *
 * To run a query within a React component, call `useGetGroupReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupReportsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetGroupReportsQuery(baseOptions: Apollo.QueryHookOptions<GetGroupReportsQuery, GetGroupReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupReportsQuery, GetGroupReportsQueryVariables>(GetGroupReportsDocument, options);
      }
export function useGetGroupReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupReportsQuery, GetGroupReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupReportsQuery, GetGroupReportsQueryVariables>(GetGroupReportsDocument, options);
        }
export type GetGroupReportsQueryHookResult = ReturnType<typeof useGetGroupReportsQuery>;
export type GetGroupReportsLazyQueryHookResult = ReturnType<typeof useGetGroupReportsLazyQuery>;
export type GetGroupReportsQueryResult = Apollo.QueryResult<GetGroupReportsQuery, GetGroupReportsQueryVariables>;
export const GetGroupReportWithTokenDocument = gql`
    query GetGroupReportWithToken($groupId: String!, $participantId: String, $surveyId: String!, $token: String!) {
  getGroupReportWithToken(
    groupId: $groupId
    surveyId: $surveyId
    participantId: $participantId
    token: $token
  ) {
    reportName
    clientId
    client {
      id
      name
      imageUrl
    }
    groupReportParticipants {
      id
      participantId
      participant {
        demographics {
          id
          title
          value
        }
      }
      reportPdfGcsName
    }
    behaviours {
      id
    }
    primaryArchetypeId
    secondaryArchetypeId
  }
}
    `;

/**
 * __useGetGroupReportWithTokenQuery__
 *
 * To run a query within a React component, call `useGetGroupReportWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupReportWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupReportWithTokenQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetGroupReportWithTokenQuery(baseOptions: Apollo.QueryHookOptions<GetGroupReportWithTokenQuery, GetGroupReportWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupReportWithTokenQuery, GetGroupReportWithTokenQueryVariables>(GetGroupReportWithTokenDocument, options);
      }
export function useGetGroupReportWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupReportWithTokenQuery, GetGroupReportWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupReportWithTokenQuery, GetGroupReportWithTokenQueryVariables>(GetGroupReportWithTokenDocument, options);
        }
export type GetGroupReportWithTokenQueryHookResult = ReturnType<typeof useGetGroupReportWithTokenQuery>;
export type GetGroupReportWithTokenLazyQueryHookResult = ReturnType<typeof useGetGroupReportWithTokenLazyQuery>;
export type GetGroupReportWithTokenQueryResult = Apollo.QueryResult<GetGroupReportWithTokenQuery, GetGroupReportWithTokenQueryVariables>;
export const GroupBehaviorProfileDocument = gql`
    query GroupBehaviorProfile($options: GroupBehaviorProfileInput!) {
  groupBehaviorProfile(options: $options) {
    archetypeId
    behaviorId
    fontColor
    fontBold
    label
    value
    color
    showLine
    priority
  }
}
    `;

/**
 * __useGroupBehaviorProfileQuery__
 *
 * To run a query within a React component, call `useGroupBehaviorProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBehaviorProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBehaviorProfileQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGroupBehaviorProfileQuery(baseOptions: Apollo.QueryHookOptions<GroupBehaviorProfileQuery, GroupBehaviorProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupBehaviorProfileQuery, GroupBehaviorProfileQueryVariables>(GroupBehaviorProfileDocument, options);
      }
export function useGroupBehaviorProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupBehaviorProfileQuery, GroupBehaviorProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupBehaviorProfileQuery, GroupBehaviorProfileQueryVariables>(GroupBehaviorProfileDocument, options);
        }
export type GroupBehaviorProfileQueryHookResult = ReturnType<typeof useGroupBehaviorProfileQuery>;
export type GroupBehaviorProfileLazyQueryHookResult = ReturnType<typeof useGroupBehaviorProfileLazyQuery>;
export type GroupBehaviorProfileQueryResult = Apollo.QueryResult<GroupBehaviorProfileQuery, GroupBehaviorProfileQueryVariables>;
export const GroupBehaviorPreferencesDocument = gql`
    query GroupBehaviorPreferences($options: GroupBehaviorPreferencesInput!) {
  groupBehaviorPreferences(options: $options) {
    participantId
    participantName
    archetypeItems {
      archetypeId
      title
      dataList {
        priority
        label
        color
        value
        shouldHighlightSection
        fontColor
      }
    }
  }
}
    `;

/**
 * __useGroupBehaviorPreferencesQuery__
 *
 * To run a query within a React component, call `useGroupBehaviorPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBehaviorPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBehaviorPreferencesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGroupBehaviorPreferencesQuery(baseOptions: Apollo.QueryHookOptions<GroupBehaviorPreferencesQuery, GroupBehaviorPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupBehaviorPreferencesQuery, GroupBehaviorPreferencesQueryVariables>(GroupBehaviorPreferencesDocument, options);
      }
export function useGroupBehaviorPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupBehaviorPreferencesQuery, GroupBehaviorPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupBehaviorPreferencesQuery, GroupBehaviorPreferencesQueryVariables>(GroupBehaviorPreferencesDocument, options);
        }
export type GroupBehaviorPreferencesQueryHookResult = ReturnType<typeof useGroupBehaviorPreferencesQuery>;
export type GroupBehaviorPreferencesLazyQueryHookResult = ReturnType<typeof useGroupBehaviorPreferencesLazyQuery>;
export type GroupBehaviorPreferencesQueryResult = Apollo.QueryResult<GroupBehaviorPreferencesQuery, GroupBehaviorPreferencesQueryVariables>;
export const GroupArchetypePreferencesDocument = gql`
    query GroupArchetypePreferences($options: GroupArchetypePreferencesInput!) {
  groupArchetypePreferences(options: $options) {
    participantName
    dataList {
      archetypeId
      label
      color
      value
      fontColor
    }
  }
}
    `;

/**
 * __useGroupArchetypePreferencesQuery__
 *
 * To run a query within a React component, call `useGroupArchetypePreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupArchetypePreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupArchetypePreferencesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGroupArchetypePreferencesQuery(baseOptions: Apollo.QueryHookOptions<GroupArchetypePreferencesQuery, GroupArchetypePreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupArchetypePreferencesQuery, GroupArchetypePreferencesQueryVariables>(GroupArchetypePreferencesDocument, options);
      }
export function useGroupArchetypePreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupArchetypePreferencesQuery, GroupArchetypePreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupArchetypePreferencesQuery, GroupArchetypePreferencesQueryVariables>(GroupArchetypePreferencesDocument, options);
        }
export type GroupArchetypePreferencesQueryHookResult = ReturnType<typeof useGroupArchetypePreferencesQuery>;
export type GroupArchetypePreferencesLazyQueryHookResult = ReturnType<typeof useGroupArchetypePreferencesLazyQuery>;
export type GroupArchetypePreferencesQueryResult = Apollo.QueryResult<GroupArchetypePreferencesQuery, GroupArchetypePreferencesQueryVariables>;
export const GetGroupParticipantReportDocument = gql`
    query GetGroupParticipantReport($surveyId: String!, $groupId: String!, $participantId: String!, $pdfLanguage: String!) {
  getGroupParticipantReport(
    surveyId: $surveyId
    groupId: $groupId
    participantId: $participantId
    pdfLanguage: $pdfLanguage
  ) {
    id
    groupReportId
    participantId
    language
    reportPdfGcsName
  }
}
    `;

/**
 * __useGetGroupParticipantReportQuery__
 *
 * To run a query within a React component, call `useGetGroupParticipantReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupParticipantReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupParticipantReportQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      groupId: // value for 'groupId'
 *      participantId: // value for 'participantId'
 *      pdfLanguage: // value for 'pdfLanguage'
 *   },
 * });
 */
export function useGetGroupParticipantReportQuery(baseOptions: Apollo.QueryHookOptions<GetGroupParticipantReportQuery, GetGroupParticipantReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupParticipantReportQuery, GetGroupParticipantReportQueryVariables>(GetGroupParticipantReportDocument, options);
      }
export function useGetGroupParticipantReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupParticipantReportQuery, GetGroupParticipantReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupParticipantReportQuery, GetGroupParticipantReportQueryVariables>(GetGroupParticipantReportDocument, options);
        }
export type GetGroupParticipantReportQueryHookResult = ReturnType<typeof useGetGroupParticipantReportQuery>;
export type GetGroupParticipantReportLazyQueryHookResult = ReturnType<typeof useGetGroupParticipantReportLazyQuery>;
export type GetGroupParticipantReportQueryResult = Apollo.QueryResult<GetGroupParticipantReportQuery, GetGroupParticipantReportQueryVariables>;
export const GetGroupReportPdfDocument = gql`
    query GetGroupReportPdf($surveyId: String!, $groupId: String!, $pdfLanguage: String!) {
  getGroupReportPdf(
    surveyId: $surveyId
    groupId: $groupId
    pdfLanguage: $pdfLanguage
  ) {
    id
    groupReportId
    language
    reportPdfGcsName
  }
}
    `;

/**
 * __useGetGroupReportPdfQuery__
 *
 * To run a query within a React component, call `useGetGroupReportPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupReportPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupReportPdfQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      groupId: // value for 'groupId'
 *      pdfLanguage: // value for 'pdfLanguage'
 *   },
 * });
 */
export function useGetGroupReportPdfQuery(baseOptions: Apollo.QueryHookOptions<GetGroupReportPdfQuery, GetGroupReportPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupReportPdfQuery, GetGroupReportPdfQueryVariables>(GetGroupReportPdfDocument, options);
      }
export function useGetGroupReportPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupReportPdfQuery, GetGroupReportPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupReportPdfQuery, GetGroupReportPdfQueryVariables>(GetGroupReportPdfDocument, options);
        }
export type GetGroupReportPdfQueryHookResult = ReturnType<typeof useGetGroupReportPdfQuery>;
export type GetGroupReportPdfLazyQueryHookResult = ReturnType<typeof useGetGroupReportPdfLazyQuery>;
export type GetGroupReportPdfQueryResult = Apollo.QueryResult<GetGroupReportPdfQuery, GetGroupReportPdfQueryVariables>;
export const GetIndividualReportPdfDocument = gql`
    query GetIndividualReportPdf($participantId: String!, $surveyId: String!, $pdfLanguage: String!, $isTargetCulture: Boolean!, $clientId: String) {
  getIndividualReportPdf(
    participantId: $participantId
    surveyId: $surveyId
    pdfLanguage: $pdfLanguage
    isTargetCulture: $isTargetCulture
    clientId: $clientId
  ) {
    id
    participantId
    surveyId
    language
    reportPdfGcsName
  }
}
    `;

/**
 * __useGetIndividualReportPdfQuery__
 *
 * To run a query within a React component, call `useGetIndividualReportPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndividualReportPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndividualReportPdfQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *      pdfLanguage: // value for 'pdfLanguage'
 *      isTargetCulture: // value for 'isTargetCulture'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetIndividualReportPdfQuery(baseOptions: Apollo.QueryHookOptions<GetIndividualReportPdfQuery, GetIndividualReportPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIndividualReportPdfQuery, GetIndividualReportPdfQueryVariables>(GetIndividualReportPdfDocument, options);
      }
export function useGetIndividualReportPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndividualReportPdfQuery, GetIndividualReportPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIndividualReportPdfQuery, GetIndividualReportPdfQueryVariables>(GetIndividualReportPdfDocument, options);
        }
export type GetIndividualReportPdfQueryHookResult = ReturnType<typeof useGetIndividualReportPdfQuery>;
export type GetIndividualReportPdfLazyQueryHookResult = ReturnType<typeof useGetIndividualReportPdfLazyQuery>;
export type GetIndividualReportPdfQueryResult = Apollo.QueryResult<GetIndividualReportPdfQuery, GetIndividualReportPdfQueryVariables>;
export const ItemsDocument = gql`
    query items {
  items {
    id
    label
  }
}
    `;

/**
 * __useItemsQuery__
 *
 * To run a query within a React component, call `useItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemsQuery(baseOptions?: Apollo.QueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
      }
export function useItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
        }
export type ItemsQueryHookResult = ReturnType<typeof useItemsQuery>;
export type ItemsLazyQueryHookResult = ReturnType<typeof useItemsLazyQuery>;
export type ItemsQueryResult = Apollo.QueryResult<ItemsQuery, ItemsQueryVariables>;
export const ParticipantDocument = gql`
    query Participant($participantId: String!, $surveyId: String!) {
  participant(participantId: $participantId, surveyId: $surveyId) {
    id
    clients {
      id
      name
      deletedDate
      behaviours {
        id
        archetypeId
      }
      primaryArchetypeId
      secondaryArchetypeId
    }
    demographics {
      id
      title
      value
    }
    userParticipants {
      id
      participantId
    }
  }
}
    `;

/**
 * __useParticipantQuery__
 *
 * To run a query within a React component, call `useParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useParticipantQuery(baseOptions: Apollo.QueryHookOptions<ParticipantQuery, ParticipantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantQuery, ParticipantQueryVariables>(ParticipantDocument, options);
      }
export function useParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantQuery, ParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantQuery, ParticipantQueryVariables>(ParticipantDocument, options);
        }
export type ParticipantQueryHookResult = ReturnType<typeof useParticipantQuery>;
export type ParticipantLazyQueryHookResult = ReturnType<typeof useParticipantLazyQuery>;
export type ParticipantQueryResult = Apollo.QueryResult<ParticipantQuery, ParticipantQueryVariables>;
export const ParticipantWithTokenDocument = gql`
    query ParticipantWithToken($participantId: String!, $surveyId: String!, $token: String!) {
  participantWithToken(
    participantId: $participantId
    surveyId: $surveyId
    token: $token
  ) {
    id
    clients {
      id
      name
      deletedDate
      behaviours {
        id
        archetypeId
      }
      primaryArchetypeId
      secondaryArchetypeId
    }
    demographics {
      id
      title
      value
    }
  }
}
    `;

/**
 * __useParticipantWithTokenQuery__
 *
 * To run a query within a React component, call `useParticipantWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantWithTokenQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useParticipantWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ParticipantWithTokenQuery, ParticipantWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantWithTokenQuery, ParticipantWithTokenQueryVariables>(ParticipantWithTokenDocument, options);
      }
export function useParticipantWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantWithTokenQuery, ParticipantWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantWithTokenQuery, ParticipantWithTokenQueryVariables>(ParticipantWithTokenDocument, options);
        }
export type ParticipantWithTokenQueryHookResult = ReturnType<typeof useParticipantWithTokenQuery>;
export type ParticipantWithTokenLazyQueryHookResult = ReturnType<typeof useParticipantWithTokenLazyQuery>;
export type ParticipantWithTokenQueryResult = Apollo.QueryResult<ParticipantWithTokenQuery, ParticipantWithTokenQueryVariables>;
export const ParticipantWithEventHistoryDocument = gql`
    query ParticipantWithEventHistory($participantId: String!, $surveyId: String!) {
  participantWithEventHistory(participantId: $participantId, surveyId: $surveyId) {
    id
    participantEventHistory {
      createdAt
      type
      data
    }
  }
}
    `;

/**
 * __useParticipantWithEventHistoryQuery__
 *
 * To run a query within a React component, call `useParticipantWithEventHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantWithEventHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantWithEventHistoryQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useParticipantWithEventHistoryQuery(baseOptions: Apollo.QueryHookOptions<ParticipantWithEventHistoryQuery, ParticipantWithEventHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantWithEventHistoryQuery, ParticipantWithEventHistoryQueryVariables>(ParticipantWithEventHistoryDocument, options);
      }
export function useParticipantWithEventHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantWithEventHistoryQuery, ParticipantWithEventHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantWithEventHistoryQuery, ParticipantWithEventHistoryQueryVariables>(ParticipantWithEventHistoryDocument, options);
        }
export type ParticipantWithEventHistoryQueryHookResult = ReturnType<typeof useParticipantWithEventHistoryQuery>;
export type ParticipantWithEventHistoryLazyQueryHookResult = ReturnType<typeof useParticipantWithEventHistoryLazyQuery>;
export type ParticipantWithEventHistoryQueryResult = Apollo.QueryResult<ParticipantWithEventHistoryQuery, ParticipantWithEventHistoryQueryVariables>;
export const ParticipantsDocument = gql`
    query Participants($options: GetPagedParticipantInput!) {
  participants(options: $options) {
    pageOffset
    pageSize
    results {
      id
      surveyStatus
      user {
        id
        email
      }
      demographics {
        title
        value
      }
      testAccount
      surveyProgress
      createdAt
      clients {
        id
        name
        deletedDate
        behaviours {
          id
        }
        primaryArchetypeId
      }
      serviceTypes {
        label
        value
      }
      userParticipants {
        id
        participantId
      }
    }
    totalCount
  }
}
    `;

/**
 * __useParticipantsQuery__
 *
 * To run a query within a React component, call `useParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useParticipantsQuery(baseOptions: Apollo.QueryHookOptions<ParticipantsQuery, ParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantsQuery, ParticipantsQueryVariables>(ParticipantsDocument, options);
      }
export function useParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantsQuery, ParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantsQuery, ParticipantsQueryVariables>(ParticipantsDocument, options);
        }
export type ParticipantsQueryHookResult = ReturnType<typeof useParticipantsQuery>;
export type ParticipantsLazyQueryHookResult = ReturnType<typeof useParticipantsLazyQuery>;
export type ParticipantsQueryResult = Apollo.QueryResult<ParticipantsQuery, ParticipantsQueryVariables>;
export const ParticipantsLightWeightDocument = gql`
    query ParticipantsLightWeight($options: GetPagedParticipantInput!) {
  participantsLightWeight(options: $options) {
    pageOffset
    pageSize
    results {
      id
      surveyStatus
      user {
        id
        email
      }
      demographics {
        title
        value
      }
      surveyProgress
      createdAt
      clients {
        id
        name
      }
      serviceTypes {
        label
        value
      }
    }
    totalCount
  }
}
    `;

/**
 * __useParticipantsLightWeightQuery__
 *
 * To run a query within a React component, call `useParticipantsLightWeightQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantsLightWeightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantsLightWeightQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useParticipantsLightWeightQuery(baseOptions: Apollo.QueryHookOptions<ParticipantsLightWeightQuery, ParticipantsLightWeightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantsLightWeightQuery, ParticipantsLightWeightQueryVariables>(ParticipantsLightWeightDocument, options);
      }
export function useParticipantsLightWeightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantsLightWeightQuery, ParticipantsLightWeightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantsLightWeightQuery, ParticipantsLightWeightQueryVariables>(ParticipantsLightWeightDocument, options);
        }
export type ParticipantsLightWeightQueryHookResult = ReturnType<typeof useParticipantsLightWeightQuery>;
export type ParticipantsLightWeightLazyQueryHookResult = ReturnType<typeof useParticipantsLightWeightLazyQuery>;
export type ParticipantsLightWeightQueryResult = Apollo.QueryResult<ParticipantsLightWeightQuery, ParticipantsLightWeightQueryVariables>;
export const AllParticipantsLightWeightDocument = gql`
    query AllParticipantsLightWeight($options: GetPagedParticipantInput!) {
  participantsLightWeight(options: $options) {
    pageOffset
    pageSize
    results {
      id
      surveyStatus
      user {
        id
        email
      }
      demographics {
        title
        value
      }
      surveyProgress
      createdAt
      projects {
        id
        name
        client {
          id
          name
        }
      }
      serviceTypes {
        label
        value
      }
    }
    totalCount
  }
}
    `;

/**
 * __useAllParticipantsLightWeightQuery__
 *
 * To run a query within a React component, call `useAllParticipantsLightWeightQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllParticipantsLightWeightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllParticipantsLightWeightQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useAllParticipantsLightWeightQuery(baseOptions: Apollo.QueryHookOptions<AllParticipantsLightWeightQuery, AllParticipantsLightWeightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllParticipantsLightWeightQuery, AllParticipantsLightWeightQueryVariables>(AllParticipantsLightWeightDocument, options);
      }
export function useAllParticipantsLightWeightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllParticipantsLightWeightQuery, AllParticipantsLightWeightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllParticipantsLightWeightQuery, AllParticipantsLightWeightQueryVariables>(AllParticipantsLightWeightDocument, options);
        }
export type AllParticipantsLightWeightQueryHookResult = ReturnType<typeof useAllParticipantsLightWeightQuery>;
export type AllParticipantsLightWeightLazyQueryHookResult = ReturnType<typeof useAllParticipantsLightWeightLazyQuery>;
export type AllParticipantsLightWeightQueryResult = Apollo.QueryResult<AllParticipantsLightWeightQuery, AllParticipantsLightWeightQueryVariables>;
export const SendInviteNotificationsDocument = gql`
    mutation SendInviteNotifications($surveyId: String!, $importId: String!) {
  sendInviteNotifications(surveyId: $surveyId, importId: $importId)
}
    `;
export type SendInviteNotificationsMutationFn = Apollo.MutationFunction<SendInviteNotificationsMutation, SendInviteNotificationsMutationVariables>;

/**
 * __useSendInviteNotificationsMutation__
 *
 * To run a mutation, you first call `useSendInviteNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteNotificationsMutation, { data, loading, error }] = useSendInviteNotificationsMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      importId: // value for 'importId'
 *   },
 * });
 */
export function useSendInviteNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SendInviteNotificationsMutation, SendInviteNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInviteNotificationsMutation, SendInviteNotificationsMutationVariables>(SendInviteNotificationsDocument, options);
      }
export type SendInviteNotificationsMutationHookResult = ReturnType<typeof useSendInviteNotificationsMutation>;
export type SendInviteNotificationsMutationResult = Apollo.MutationResult<SendInviteNotificationsMutation>;
export type SendInviteNotificationsMutationOptions = Apollo.BaseMutationOptions<SendInviteNotificationsMutation, SendInviteNotificationsMutationVariables>;
export const ParticipantSurveysDocument = gql`
    query ParticipantSurveys($options: PagedParticipantInput) {
  participantSurveys(options: $options) {
    results {
      id
      participantEventHistory {
        createdAt
        type
        data
      }
      survey {
        id
        title
        deleted
        surveySchemaId
      }
      surveyProgress
    }
    totalCount
    pageOffset
    pageSize
  }
}
    `;

/**
 * __useParticipantSurveysQuery__
 *
 * To run a query within a React component, call `useParticipantSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantSurveysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useParticipantSurveysQuery(baseOptions?: Apollo.QueryHookOptions<ParticipantSurveysQuery, ParticipantSurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantSurveysQuery, ParticipantSurveysQueryVariables>(ParticipantSurveysDocument, options);
      }
export function useParticipantSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantSurveysQuery, ParticipantSurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantSurveysQuery, ParticipantSurveysQueryVariables>(ParticipantSurveysDocument, options);
        }
export type ParticipantSurveysQueryHookResult = ReturnType<typeof useParticipantSurveysQuery>;
export type ParticipantSurveysLazyQueryHookResult = ReturnType<typeof useParticipantSurveysLazyQuery>;
export type ParticipantSurveysQueryResult = Apollo.QueryResult<ParticipantSurveysQuery, ParticipantSurveysQueryVariables>;
export const ParticipantWithSurveyDocument = gql`
    query ParticipantWithSurvey($participantId: String!, $surveyId: String!) {
  participantWithSurvey(participantId: $participantId, surveyId: $surveyId) {
    survey {
      id
      title
      questionOrder {
        type
        id
        index
        orderIndex
        questionGroupIndex
        categoryIndex
      }
      surveySchema {
        schema {
          categories {
            id
            title
            order
            name
            questionGroups {
              id
              title
              name
              order
              description
              questions {
                id
                questionText
                type
                order
                options {
                  id
                  order
                  relatedId
                  score
                  text
                  behaviourId
                }
              }
            }
          }
        }
      }
    }
    participant {
      id
      demographics {
        id
        title
        value
      }
      responses {
        categoryId
        questionGroupId
        questionId
        answer {
          optionId
          order
        }
      }
      surveyStatus
      clients {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useParticipantWithSurveyQuery__
 *
 * To run a query within a React component, call `useParticipantWithSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantWithSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantWithSurveyQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useParticipantWithSurveyQuery(baseOptions: Apollo.QueryHookOptions<ParticipantWithSurveyQuery, ParticipantWithSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantWithSurveyQuery, ParticipantWithSurveyQueryVariables>(ParticipantWithSurveyDocument, options);
      }
export function useParticipantWithSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantWithSurveyQuery, ParticipantWithSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantWithSurveyQuery, ParticipantWithSurveyQueryVariables>(ParticipantWithSurveyDocument, options);
        }
export type ParticipantWithSurveyQueryHookResult = ReturnType<typeof useParticipantWithSurveyQuery>;
export type ParticipantWithSurveyLazyQueryHookResult = ReturnType<typeof useParticipantWithSurveyLazyQuery>;
export type ParticipantWithSurveyQueryResult = Apollo.QueryResult<ParticipantWithSurveyQuery, ParticipantWithSurveyQueryVariables>;
export const ParseCsvUsersDocument = gql`
    query ParseCSVUsers($file: Upload!) {
  parseCSVUsers(file: $file) {
    parsedRecords {
      data {
        key
        value
      }
    }
    errors {
      row
      message
    }
  }
}
    `;

/**
 * __useParseCsvUsersQuery__
 *
 * To run a query within a React component, call `useParseCsvUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useParseCsvUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParseCsvUsersQuery({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useParseCsvUsersQuery(baseOptions: Apollo.QueryHookOptions<ParseCsvUsersQuery, ParseCsvUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParseCsvUsersQuery, ParseCsvUsersQueryVariables>(ParseCsvUsersDocument, options);
      }
export function useParseCsvUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParseCsvUsersQuery, ParseCsvUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParseCsvUsersQuery, ParseCsvUsersQueryVariables>(ParseCsvUsersDocument, options);
        }
export type ParseCsvUsersQueryHookResult = ReturnType<typeof useParseCsvUsersQuery>;
export type ParseCsvUsersLazyQueryHookResult = ReturnType<typeof useParseCsvUsersLazyQuery>;
export type ParseCsvUsersQueryResult = Apollo.QueryResult<ParseCsvUsersQuery, ParseCsvUsersQueryVariables>;
export const ParticipantEventHistoryDocument = gql`
    query ParticipantEventHistory($participantId: String!, $surveyId: String!) {
  participantEventHistory(participantId: $participantId, surveyId: $surveyId) {
    id
    surveyId
    type
    data
  }
}
    `;

/**
 * __useParticipantEventHistoryQuery__
 *
 * To run a query within a React component, call `useParticipantEventHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantEventHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantEventHistoryQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useParticipantEventHistoryQuery(baseOptions: Apollo.QueryHookOptions<ParticipantEventHistoryQuery, ParticipantEventHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantEventHistoryQuery, ParticipantEventHistoryQueryVariables>(ParticipantEventHistoryDocument, options);
      }
export function useParticipantEventHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantEventHistoryQuery, ParticipantEventHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantEventHistoryQuery, ParticipantEventHistoryQueryVariables>(ParticipantEventHistoryDocument, options);
        }
export type ParticipantEventHistoryQueryHookResult = ReturnType<typeof useParticipantEventHistoryQuery>;
export type ParticipantEventHistoryLazyQueryHookResult = ReturnType<typeof useParticipantEventHistoryLazyQuery>;
export type ParticipantEventHistoryQueryResult = Apollo.QueryResult<ParticipantEventHistoryQuery, ParticipantEventHistoryQueryVariables>;
export const GetDuplicateParticipantsDocument = gql`
    query GetDuplicateParticipants($options: GetDuplicateParticipants!) {
  getDuplicateParticipants(options: $options) {
    id
    user {
      id
      email
    }
    demographics {
      id
      title
      value
    }
    clients {
      id
      name
    }
    projects {
      id
      name
    }
  }
}
    `;

/**
 * __useGetDuplicateParticipantsQuery__
 *
 * To run a query within a React component, call `useGetDuplicateParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuplicateParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuplicateParticipantsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetDuplicateParticipantsQuery(baseOptions: Apollo.QueryHookOptions<GetDuplicateParticipantsQuery, GetDuplicateParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDuplicateParticipantsQuery, GetDuplicateParticipantsQueryVariables>(GetDuplicateParticipantsDocument, options);
      }
export function useGetDuplicateParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDuplicateParticipantsQuery, GetDuplicateParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDuplicateParticipantsQuery, GetDuplicateParticipantsQueryVariables>(GetDuplicateParticipantsDocument, options);
        }
export type GetDuplicateParticipantsQueryHookResult = ReturnType<typeof useGetDuplicateParticipantsQuery>;
export type GetDuplicateParticipantsLazyQueryHookResult = ReturnType<typeof useGetDuplicateParticipantsLazyQuery>;
export type GetDuplicateParticipantsQueryResult = Apollo.QueryResult<GetDuplicateParticipantsQuery, GetDuplicateParticipantsQueryVariables>;
export const GetClientProjectsDocument = gql`
    query GetClientProjects($options: GetProjectsInput!) {
  getClientProjects(options: $options) {
    results {
      id
      name
      createdAt
      updatedAt
      participantCount
      participantCompletedCount
    }
    pageSize
    totalCount
  }
}
    `;

/**
 * __useGetClientProjectsQuery__
 *
 * To run a query within a React component, call `useGetClientProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientProjectsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetClientProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetClientProjectsQuery, GetClientProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientProjectsQuery, GetClientProjectsQueryVariables>(GetClientProjectsDocument, options);
      }
export function useGetClientProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientProjectsQuery, GetClientProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientProjectsQuery, GetClientProjectsQueryVariables>(GetClientProjectsDocument, options);
        }
export type GetClientProjectsQueryHookResult = ReturnType<typeof useGetClientProjectsQuery>;
export type GetClientProjectsLazyQueryHookResult = ReturnType<typeof useGetClientProjectsLazyQuery>;
export type GetClientProjectsQueryResult = Apollo.QueryResult<GetClientProjectsQuery, GetClientProjectsQueryVariables>;
export const GetClientProjectInfoDocument = gql`
    query getClientProjectInfo($id: String!) {
  getClientProject(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetClientProjectInfoQuery__
 *
 * To run a query within a React component, call `useGetClientProjectInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientProjectInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientProjectInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientProjectInfoQuery(baseOptions: Apollo.QueryHookOptions<GetClientProjectInfoQuery, GetClientProjectInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientProjectInfoQuery, GetClientProjectInfoQueryVariables>(GetClientProjectInfoDocument, options);
      }
export function useGetClientProjectInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientProjectInfoQuery, GetClientProjectInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientProjectInfoQuery, GetClientProjectInfoQueryVariables>(GetClientProjectInfoDocument, options);
        }
export type GetClientProjectInfoQueryHookResult = ReturnType<typeof useGetClientProjectInfoQuery>;
export type GetClientProjectInfoLazyQueryHookResult = ReturnType<typeof useGetClientProjectInfoLazyQuery>;
export type GetClientProjectInfoQueryResult = Apollo.QueryResult<GetClientProjectInfoQuery, GetClientProjectInfoQueryVariables>;
export const GetAdminProjectsDocument = gql`
    query GetAdminProjects($options: GetAdminProjectsInput!) {
  getAdminProjects(options: $options) {
    projectId
    projectName
    assigned
    clientId
  }
}
    `;

/**
 * __useGetAdminProjectsQuery__
 *
 * To run a query within a React component, call `useGetAdminProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminProjectsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetAdminProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminProjectsQuery, GetAdminProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminProjectsQuery, GetAdminProjectsQueryVariables>(GetAdminProjectsDocument, options);
      }
export function useGetAdminProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminProjectsQuery, GetAdminProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminProjectsQuery, GetAdminProjectsQueryVariables>(GetAdminProjectsDocument, options);
        }
export type GetAdminProjectsQueryHookResult = ReturnType<typeof useGetAdminProjectsQuery>;
export type GetAdminProjectsLazyQueryHookResult = ReturnType<typeof useGetAdminProjectsLazyQuery>;
export type GetAdminProjectsQueryResult = Apollo.QueryResult<GetAdminProjectsQuery, GetAdminProjectsQueryVariables>;
export const ReportingForParticipantDocument = gql`
    query ReportingForParticipant($participantId: String!, $surveyId: String!, $languageCode: String) {
  reportingForParticipant(
    participantId: $participantId
    surveyId: $surveyId
    languageCode: $languageCode
  ) {
    id
    surveyId
    participantId
    archetypeId
    behaviourId
    score
    scoreText
  }
}
    `;

/**
 * __useReportingForParticipantQuery__
 *
 * To run a query within a React component, call `useReportingForParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingForParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingForParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useReportingForParticipantQuery(baseOptions: Apollo.QueryHookOptions<ReportingForParticipantQuery, ReportingForParticipantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportingForParticipantQuery, ReportingForParticipantQueryVariables>(ReportingForParticipantDocument, options);
      }
export function useReportingForParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportingForParticipantQuery, ReportingForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportingForParticipantQuery, ReportingForParticipantQueryVariables>(ReportingForParticipantDocument, options);
        }
export type ReportingForParticipantQueryHookResult = ReturnType<typeof useReportingForParticipantQuery>;
export type ReportingForParticipantLazyQueryHookResult = ReturnType<typeof useReportingForParticipantLazyQuery>;
export type ReportingForParticipantQueryResult = Apollo.QueryResult<ReportingForParticipantQuery, ReportingForParticipantQueryVariables>;
export const ReportingForParticipantWithTokenDocument = gql`
    query ReportingForParticipantWithToken($participantId: String!, $surveyId: String!, $token: String!, $languageCode: String) {
  reportingForParticipantWithToken(
    participantId: $participantId
    surveyId: $surveyId
    token: $token
    languageCode: $languageCode
  ) {
    id
    surveyId
    participantId
    archetypeId
    behaviourId
    score
    scoreText
  }
}
    `;

/**
 * __useReportingForParticipantWithTokenQuery__
 *
 * To run a query within a React component, call `useReportingForParticipantWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingForParticipantWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingForParticipantWithTokenQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      surveyId: // value for 'surveyId'
 *      token: // value for 'token'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useReportingForParticipantWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ReportingForParticipantWithTokenQuery, ReportingForParticipantWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportingForParticipantWithTokenQuery, ReportingForParticipantWithTokenQueryVariables>(ReportingForParticipantWithTokenDocument, options);
      }
export function useReportingForParticipantWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportingForParticipantWithTokenQuery, ReportingForParticipantWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportingForParticipantWithTokenQuery, ReportingForParticipantWithTokenQueryVariables>(ReportingForParticipantWithTokenDocument, options);
        }
export type ReportingForParticipantWithTokenQueryHookResult = ReturnType<typeof useReportingForParticipantWithTokenQuery>;
export type ReportingForParticipantWithTokenLazyQueryHookResult = ReturnType<typeof useReportingForParticipantWithTokenLazyQuery>;
export type ReportingForParticipantWithTokenQueryResult = Apollo.QueryResult<ReportingForParticipantWithTokenQuery, ReportingForParticipantWithTokenQueryVariables>;
export const ReportingForGroupDocument = gql`
    query ReportingForGroup($groupId: String!, $surveyId: String!) {
  reportingForGroup(groupId: $groupId, surveyId: $surveyId) {
    id
    participantId
    archetypeId
    behaviourId
    score
    scoreText
    surveyId
  }
}
    `;

/**
 * __useReportingForGroupQuery__
 *
 * To run a query within a React component, call `useReportingForGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingForGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingForGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useReportingForGroupQuery(baseOptions: Apollo.QueryHookOptions<ReportingForGroupQuery, ReportingForGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportingForGroupQuery, ReportingForGroupQueryVariables>(ReportingForGroupDocument, options);
      }
export function useReportingForGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportingForGroupQuery, ReportingForGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportingForGroupQuery, ReportingForGroupQueryVariables>(ReportingForGroupDocument, options);
        }
export type ReportingForGroupQueryHookResult = ReturnType<typeof useReportingForGroupQuery>;
export type ReportingForGroupLazyQueryHookResult = ReturnType<typeof useReportingForGroupLazyQuery>;
export type ReportingForGroupQueryResult = Apollo.QueryResult<ReportingForGroupQuery, ReportingForGroupQueryVariables>;
export const ReportingForGroupWithTokenDocument = gql`
    query ReportingForGroupWithToken($token: String!, $groupId: String!, $surveyId: String!, $participantId: String, $languageCode: String) {
  reportingForGroupWithToken(
    groupId: $groupId
    surveyId: $surveyId
    token: $token
    participantId: $participantId
    languageCode: $languageCode
  ) {
    id
    participantId
    archetypeId
    behaviourId
    score
    scoreText
    surveyId
  }
}
    `;

/**
 * __useReportingForGroupWithTokenQuery__
 *
 * To run a query within a React component, call `useReportingForGroupWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingForGroupWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingForGroupWithTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *      groupId: // value for 'groupId'
 *      surveyId: // value for 'surveyId'
 *      participantId: // value for 'participantId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useReportingForGroupWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ReportingForGroupWithTokenQuery, ReportingForGroupWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportingForGroupWithTokenQuery, ReportingForGroupWithTokenQueryVariables>(ReportingForGroupWithTokenDocument, options);
      }
export function useReportingForGroupWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportingForGroupWithTokenQuery, ReportingForGroupWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportingForGroupWithTokenQuery, ReportingForGroupWithTokenQueryVariables>(ReportingForGroupWithTokenDocument, options);
        }
export type ReportingForGroupWithTokenQueryHookResult = ReturnType<typeof useReportingForGroupWithTokenQuery>;
export type ReportingForGroupWithTokenLazyQueryHookResult = ReturnType<typeof useReportingForGroupWithTokenLazyQuery>;
export type ReportingForGroupWithTokenQueryResult = Apollo.QueryResult<ReportingForGroupWithTokenQuery, ReportingForGroupWithTokenQueryVariables>;
export const ReportingForTeamDocument = gql`
    query ReportingForTeam($teamReportId: String!, $surveyId: String!) {
  reportingForTeam(teamReportId: $teamReportId, surveyId: $surveyId) {
    id
    participantId
    archetypeId
    behaviourId
    score
    scoreText
    surveyId
  }
}
    `;

/**
 * __useReportingForTeamQuery__
 *
 * To run a query within a React component, call `useReportingForTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingForTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingForTeamQuery({
 *   variables: {
 *      teamReportId: // value for 'teamReportId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useReportingForTeamQuery(baseOptions: Apollo.QueryHookOptions<ReportingForTeamQuery, ReportingForTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportingForTeamQuery, ReportingForTeamQueryVariables>(ReportingForTeamDocument, options);
      }
export function useReportingForTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportingForTeamQuery, ReportingForTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportingForTeamQuery, ReportingForTeamQueryVariables>(ReportingForTeamDocument, options);
        }
export type ReportingForTeamQueryHookResult = ReturnType<typeof useReportingForTeamQuery>;
export type ReportingForTeamLazyQueryHookResult = ReturnType<typeof useReportingForTeamLazyQuery>;
export type ReportingForTeamQueryResult = Apollo.QueryResult<ReportingForTeamQuery, ReportingForTeamQueryVariables>;
export const ReportingForTeamWithTokenDocument = gql`
    query ReportingForTeamWithToken($token: String!, $teamReportId: String!, $surveyId: String!) {
  reportingForTeamWithToken(
    token: $token
    teamReportId: $teamReportId
    surveyId: $surveyId
  ) {
    id
    participantId
    participant {
      id
      user {
        id
        firstName
        lastName
      }
      demographics {
        id
        value
        title
      }
    }
    archetypeId
    behaviourId
    score
    scoreText
    surveyId
  }
}
    `;

/**
 * __useReportingForTeamWithTokenQuery__
 *
 * To run a query within a React component, call `useReportingForTeamWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingForTeamWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingForTeamWithTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *      teamReportId: // value for 'teamReportId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useReportingForTeamWithTokenQuery(baseOptions: Apollo.QueryHookOptions<ReportingForTeamWithTokenQuery, ReportingForTeamWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportingForTeamWithTokenQuery, ReportingForTeamWithTokenQueryVariables>(ReportingForTeamWithTokenDocument, options);
      }
export function useReportingForTeamWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportingForTeamWithTokenQuery, ReportingForTeamWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportingForTeamWithTokenQuery, ReportingForTeamWithTokenQueryVariables>(ReportingForTeamWithTokenDocument, options);
        }
export type ReportingForTeamWithTokenQueryHookResult = ReturnType<typeof useReportingForTeamWithTokenQuery>;
export type ReportingForTeamWithTokenLazyQueryHookResult = ReturnType<typeof useReportingForTeamWithTokenLazyQuery>;
export type ReportingForTeamWithTokenQueryResult = Apollo.QueryResult<ReportingForTeamWithTokenQuery, ReportingForTeamWithTokenQueryVariables>;
export const GetReportPdfDownloadUrlDocument = gql`
    query getReportPdfDownloadUrl($reportPdfGcsName: String!) {
  getReportPdfDownloadUrl(reportPdfGcsName: $reportPdfGcsName)
}
    `;

/**
 * __useGetReportPdfDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGetReportPdfDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportPdfDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportPdfDownloadUrlQuery({
 *   variables: {
 *      reportPdfGcsName: // value for 'reportPdfGcsName'
 *   },
 * });
 */
export function useGetReportPdfDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetReportPdfDownloadUrlQuery, GetReportPdfDownloadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportPdfDownloadUrlQuery, GetReportPdfDownloadUrlQueryVariables>(GetReportPdfDownloadUrlDocument, options);
      }
export function useGetReportPdfDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportPdfDownloadUrlQuery, GetReportPdfDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportPdfDownloadUrlQuery, GetReportPdfDownloadUrlQueryVariables>(GetReportPdfDownloadUrlDocument, options);
        }
export type GetReportPdfDownloadUrlQueryHookResult = ReturnType<typeof useGetReportPdfDownloadUrlQuery>;
export type GetReportPdfDownloadUrlLazyQueryHookResult = ReturnType<typeof useGetReportPdfDownloadUrlLazyQuery>;
export type GetReportPdfDownloadUrlQueryResult = Apollo.QueryResult<GetReportPdfDownloadUrlQuery, GetReportPdfDownloadUrlQueryVariables>;
export const SecretItemsDocument = gql`
    query secretItems {
  secretItems {
    id
    label
    description
  }
}
    `;

/**
 * __useSecretItemsQuery__
 *
 * To run a query within a React component, call `useSecretItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecretItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecretItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecretItemsQuery(baseOptions?: Apollo.QueryHookOptions<SecretItemsQuery, SecretItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SecretItemsQuery, SecretItemsQueryVariables>(SecretItemsDocument, options);
      }
export function useSecretItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecretItemsQuery, SecretItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SecretItemsQuery, SecretItemsQueryVariables>(SecretItemsDocument, options);
        }
export type SecretItemsQueryHookResult = ReturnType<typeof useSecretItemsQuery>;
export type SecretItemsLazyQueryHookResult = ReturnType<typeof useSecretItemsLazyQuery>;
export type SecretItemsQueryResult = Apollo.QueryResult<SecretItemsQuery, SecretItemsQueryVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    id
    firstName
    lastName
    email
    firebaseId
    gender
    dob
    roles
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
export const SuveySchemasDocument = gql`
    query SuveySchemas {
  suveySchemas {
    id
    title
    version
    schema {
      categories {
        questionGroups {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useSuveySchemasQuery__
 *
 * To run a query within a React component, call `useSuveySchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuveySchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuveySchemasQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuveySchemasQuery(baseOptions?: Apollo.QueryHookOptions<SuveySchemasQuery, SuveySchemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuveySchemasQuery, SuveySchemasQueryVariables>(SuveySchemasDocument, options);
      }
export function useSuveySchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuveySchemasQuery, SuveySchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuveySchemasQuery, SuveySchemasQueryVariables>(SuveySchemasDocument, options);
        }
export type SuveySchemasQueryHookResult = ReturnType<typeof useSuveySchemasQuery>;
export type SuveySchemasLazyQueryHookResult = ReturnType<typeof useSuveySchemasLazyQuery>;
export type SuveySchemasQueryResult = Apollo.QueryResult<SuveySchemasQuery, SuveySchemasQueryVariables>;
export const SurveysDocument = gql`
    query Surveys($options: GetSurveysByClientQueryInput) {
  surveys(options: $options) {
    pageOffset
    pageSize
    results {
      id
      title
    }
    totalCount
  }
}
    `;

/**
 * __useSurveysQuery__
 *
 * To run a query within a React component, call `useSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSurveysQuery(baseOptions?: Apollo.QueryHookOptions<SurveysQuery, SurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveysQuery, SurveysQueryVariables>(SurveysDocument, options);
      }
export function useSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysQuery, SurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveysQuery, SurveysQueryVariables>(SurveysDocument, options);
        }
export type SurveysQueryHookResult = ReturnType<typeof useSurveysQuery>;
export type SurveysLazyQueryHookResult = ReturnType<typeof useSurveysLazyQuery>;
export type SurveysQueryResult = Apollo.QueryResult<SurveysQuery, SurveysQueryVariables>;
export const SurveyDocument = gql`
    query Survey($options: GetSurveyQueryInput!) {
  survey(options: $options) {
    id
    surveySchemaId
    title
    demographicsRequired {
      id
      title
    }
    surveySchema {
      schema {
        categories {
          id
          name
          title
          order
          questionGroups {
            id
            name
            title
            description
            order
            questions {
              id
              type
              order
              defaultValueIfUnanswered
              isOptional
              questionText
              options {
                id
                text
              }
            }
          }
        }
      }
    }
    surveyEventHistory {
      type
    }
  }
}
    `;

/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSurveyQuery(baseOptions: Apollo.QueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
      }
export function useSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
        }
export type SurveyQueryHookResult = ReturnType<typeof useSurveyQuery>;
export type SurveyLazyQueryHookResult = ReturnType<typeof useSurveyLazyQuery>;
export type SurveyQueryResult = Apollo.QueryResult<SurveyQuery, SurveyQueryVariables>;
export const SurveyWithTokenDocument = gql`
    query SurveyWithToken($options: GetSurveyQueryInput!, $token: String!) {
  surveyWithToken(options: $options, token: $token) {
    id
    surveySchemaId
    title
    demographicsRequired {
      id
      title
    }
    surveySchema {
      schema {
        categories {
          id
          name
          title
          order
          questionGroups {
            id
            name
            title
            description
            order
            questions {
              id
              type
              order
              defaultValueIfUnanswered
              isOptional
              questionText
              options {
                id
                text
              }
            }
          }
        }
      }
    }
    surveyEventHistory {
      type
    }
  }
}
    `;

/**
 * __useSurveyWithTokenQuery__
 *
 * To run a query within a React component, call `useSurveyWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyWithTokenQuery({
 *   variables: {
 *      options: // value for 'options'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSurveyWithTokenQuery(baseOptions: Apollo.QueryHookOptions<SurveyWithTokenQuery, SurveyWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyWithTokenQuery, SurveyWithTokenQueryVariables>(SurveyWithTokenDocument, options);
      }
export function useSurveyWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyWithTokenQuery, SurveyWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyWithTokenQuery, SurveyWithTokenQueryVariables>(SurveyWithTokenDocument, options);
        }
export type SurveyWithTokenQueryHookResult = ReturnType<typeof useSurveyWithTokenQuery>;
export type SurveyWithTokenLazyQueryHookResult = ReturnType<typeof useSurveyWithTokenLazyQuery>;
export type SurveyWithTokenQueryResult = Apollo.QueryResult<SurveyWithTokenQuery, SurveyWithTokenQueryVariables>;
export const GetSurveyProgressDocument = gql`
    query GetSurveyProgress($surveyId: String!) {
  getSurveyProgress(surveyId: $surveyId) {
    surveyInvitationStatus {
      type
      data {
        generated
        sent
        participants
      }
    }
    usersCompletedSurvey
    participantCount
  }
}
    `;

/**
 * __useGetSurveyProgressQuery__
 *
 * To run a query within a React component, call `useGetSurveyProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyProgressQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetSurveyProgressQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyProgressQuery, GetSurveyProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyProgressQuery, GetSurveyProgressQueryVariables>(GetSurveyProgressDocument, options);
      }
export function useGetSurveyProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyProgressQuery, GetSurveyProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyProgressQuery, GetSurveyProgressQueryVariables>(GetSurveyProgressDocument, options);
        }
export type GetSurveyProgressQueryHookResult = ReturnType<typeof useGetSurveyProgressQuery>;
export type GetSurveyProgressLazyQueryHookResult = ReturnType<typeof useGetSurveyProgressLazyQuery>;
export type GetSurveyProgressQueryResult = Apollo.QueryResult<GetSurveyProgressQuery, GetSurveyProgressQueryVariables>;
export const GetSurveyEmailTemplateDocument = gql`
    query GetSurveyEmailTemplate($surveyId: String!, $emailType: String!) {
  getSurveyEmailTemplate(surveyId: $surveyId, emailType: $emailType) {
    template {
      subject
      body
      linkText
    }
    reminders {
      id
      time
      timezoneName
      timePeriod
    }
  }
}
    `;

/**
 * __useGetSurveyEmailTemplateQuery__
 *
 * To run a query within a React component, call `useGetSurveyEmailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyEmailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyEmailTemplateQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      emailType: // value for 'emailType'
 *   },
 * });
 */
export function useGetSurveyEmailTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyEmailTemplateQuery, GetSurveyEmailTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyEmailTemplateQuery, GetSurveyEmailTemplateQueryVariables>(GetSurveyEmailTemplateDocument, options);
      }
export function useGetSurveyEmailTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyEmailTemplateQuery, GetSurveyEmailTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyEmailTemplateQuery, GetSurveyEmailTemplateQueryVariables>(GetSurveyEmailTemplateDocument, options);
        }
export type GetSurveyEmailTemplateQueryHookResult = ReturnType<typeof useGetSurveyEmailTemplateQuery>;
export type GetSurveyEmailTemplateLazyQueryHookResult = ReturnType<typeof useGetSurveyEmailTemplateLazyQuery>;
export type GetSurveyEmailTemplateQueryResult = Apollo.QueryResult<GetSurveyEmailTemplateQuery, GetSurveyEmailTemplateQueryVariables>;
export const GetTeamReportDocument = gql`
    query GetTeamReport($teamReportId: String!, $surveyId: String!) {
  getTeamReport(teamReportId: $teamReportId, surveyId: $surveyId) {
    id
    reportName
    surveyId
    createdAt
    updatedAt
    clientId
    client {
      primaryArchetypeId
      secondaryArchetypeId
    }
    clientId
    client {
      primaryArchetypeId
      secondaryArchetypeId
    }
    teamReportParticipants {
      id
      teamReportId
      participantId
      reportPdfGcsName
      participant {
        user {
          firstName
          lastName
        }
        demographics {
          id
          title
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetTeamReportQuery__
 *
 * To run a query within a React component, call `useGetTeamReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamReportQuery({
 *   variables: {
 *      teamReportId: // value for 'teamReportId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetTeamReportQuery(baseOptions: Apollo.QueryHookOptions<GetTeamReportQuery, GetTeamReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamReportQuery, GetTeamReportQueryVariables>(GetTeamReportDocument, options);
      }
export function useGetTeamReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamReportQuery, GetTeamReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamReportQuery, GetTeamReportQueryVariables>(GetTeamReportDocument, options);
        }
export type GetTeamReportQueryHookResult = ReturnType<typeof useGetTeamReportQuery>;
export type GetTeamReportLazyQueryHookResult = ReturnType<typeof useGetTeamReportLazyQuery>;
export type GetTeamReportQueryResult = Apollo.QueryResult<GetTeamReportQuery, GetTeamReportQueryVariables>;
export const GetTeamReportWithTokenDocument = gql`
    query GetTeamReportWithToken($token: String!, $teamReportId: String!, $surveyId: String!) {
  getTeamReportWithToken(
    token: $token
    teamReportId: $teamReportId
    surveyId: $surveyId
  ) {
    reportName
    clientId
    client {
      primaryArchetypeId
      secondaryArchetypeId
      behaviours {
        id
        archetypeId
      }
    }
    client {
      primaryArchetypeId
      secondaryArchetypeId
      behaviours {
        id
        archetypeId
      }
    }
    teamReportParticipants {
      id
      participantId
      participant {
        reportings {
          id
          behaviourId
          archetypeId
          score
        }
        user {
          id
          firstName
          lastName
        }
        demographics {
          id
          title
          value
        }
      }
      reportPdfGcsName
    }
  }
}
    `;

/**
 * __useGetTeamReportWithTokenQuery__
 *
 * To run a query within a React component, call `useGetTeamReportWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamReportWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamReportWithTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *      teamReportId: // value for 'teamReportId'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetTeamReportWithTokenQuery(baseOptions: Apollo.QueryHookOptions<GetTeamReportWithTokenQuery, GetTeamReportWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamReportWithTokenQuery, GetTeamReportWithTokenQueryVariables>(GetTeamReportWithTokenDocument, options);
      }
export function useGetTeamReportWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamReportWithTokenQuery, GetTeamReportWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamReportWithTokenQuery, GetTeamReportWithTokenQueryVariables>(GetTeamReportWithTokenDocument, options);
        }
export type GetTeamReportWithTokenQueryHookResult = ReturnType<typeof useGetTeamReportWithTokenQuery>;
export type GetTeamReportWithTokenLazyQueryHookResult = ReturnType<typeof useGetTeamReportWithTokenLazyQuery>;
export type GetTeamReportWithTokenQueryResult = Apollo.QueryResult<GetTeamReportWithTokenQuery, GetTeamReportWithTokenQueryVariables>;
export const GetTeamReportsDocument = gql`
    query GetTeamReports($options: GetTeamReportsInput!) {
  getTeamReports(options: $options) {
    pageSize
    pageOffset
    totalCount
    results {
      id
      reportName
      surveyId
      createdAt
      updatedAt
      clientId
      isTargetCulture
    }
  }
}
    `;

/**
 * __useGetTeamReportsQuery__
 *
 * To run a query within a React component, call `useGetTeamReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamReportsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetTeamReportsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamReportsQuery, GetTeamReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamReportsQuery, GetTeamReportsQueryVariables>(GetTeamReportsDocument, options);
      }
export function useGetTeamReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamReportsQuery, GetTeamReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamReportsQuery, GetTeamReportsQueryVariables>(GetTeamReportsDocument, options);
        }
export type GetTeamReportsQueryHookResult = ReturnType<typeof useGetTeamReportsQuery>;
export type GetTeamReportsLazyQueryHookResult = ReturnType<typeof useGetTeamReportsLazyQuery>;
export type GetTeamReportsQueryResult = Apollo.QueryResult<GetTeamReportsQuery, GetTeamReportsQueryVariables>;
export const GetTeamReportPdfDocument = gql`
    query GetTeamReportPdf($surveyId: String!, $teamReportId: String!, $pdfLanguage: String!, $isTargetCulture: Boolean!) {
  getTeamReportPdf(
    surveyId: $surveyId
    teamReportId: $teamReportId
    pdfLanguage: $pdfLanguage
    isTargetCulture: $isTargetCulture
  ) {
    id
    teamReportId
    language
    reportPdfGcsName
  }
}
    `;

/**
 * __useGetTeamReportPdfQuery__
 *
 * To run a query within a React component, call `useGetTeamReportPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamReportPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamReportPdfQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      teamReportId: // value for 'teamReportId'
 *      pdfLanguage: // value for 'pdfLanguage'
 *      isTargetCulture: // value for 'isTargetCulture'
 *   },
 * });
 */
export function useGetTeamReportPdfQuery(baseOptions: Apollo.QueryHookOptions<GetTeamReportPdfQuery, GetTeamReportPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamReportPdfQuery, GetTeamReportPdfQueryVariables>(GetTeamReportPdfDocument, options);
      }
export function useGetTeamReportPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamReportPdfQuery, GetTeamReportPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamReportPdfQuery, GetTeamReportPdfQueryVariables>(GetTeamReportPdfDocument, options);
        }
export type GetTeamReportPdfQueryHookResult = ReturnType<typeof useGetTeamReportPdfQuery>;
export type GetTeamReportPdfLazyQueryHookResult = ReturnType<typeof useGetTeamReportPdfLazyQuery>;
export type GetTeamReportPdfQueryResult = Apollo.QueryResult<GetTeamReportPdfQuery, GetTeamReportPdfQueryVariables>;
export const UsersDocument = gql`
    query Users($options: GetUsersQueryInput!) {
  users(options: $options) {
    pageSize
    pageOffset
    totalCount
    results {
      id
      firstName
      lastName
      email
      role
      status
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query User($options: QueryUserInput!) {
  user(options: $options) {
    user {
      id
      firebaseId
      firstName
      lastName
      email
      role
      gender
      dob
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const IsUserEmailVerifiedDocument = gql`
    query IsUserEmailVerified($email: String!) {
  isUserEmailVerified(email: $email)
}
    `;

/**
 * __useIsUserEmailVerifiedQuery__
 *
 * To run a query within a React component, call `useIsUserEmailVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserEmailVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserEmailVerifiedQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsUserEmailVerifiedQuery(baseOptions: Apollo.QueryHookOptions<IsUserEmailVerifiedQuery, IsUserEmailVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUserEmailVerifiedQuery, IsUserEmailVerifiedQueryVariables>(IsUserEmailVerifiedDocument, options);
      }
export function useIsUserEmailVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserEmailVerifiedQuery, IsUserEmailVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUserEmailVerifiedQuery, IsUserEmailVerifiedQueryVariables>(IsUserEmailVerifiedDocument, options);
        }
export type IsUserEmailVerifiedQueryHookResult = ReturnType<typeof useIsUserEmailVerifiedQuery>;
export type IsUserEmailVerifiedLazyQueryHookResult = ReturnType<typeof useIsUserEmailVerifiedLazyQuery>;
export type IsUserEmailVerifiedQueryResult = Apollo.QueryResult<IsUserEmailVerifiedQuery, IsUserEmailVerifiedQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($options: UpdateProfileInput!) {
  updateProfile(options: $options) {
    id
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const GetCtaUrlForUserDocument = gql`
    query GetCtaUrlForUser($email: String!) {
  getCtaUrlForUser(email: $email)
}
    `;

/**
 * __useGetCtaUrlForUserQuery__
 *
 * To run a query within a React component, call `useGetCtaUrlForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCtaUrlForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCtaUrlForUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCtaUrlForUserQuery(baseOptions: Apollo.QueryHookOptions<GetCtaUrlForUserQuery, GetCtaUrlForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCtaUrlForUserQuery, GetCtaUrlForUserQueryVariables>(GetCtaUrlForUserDocument, options);
      }
export function useGetCtaUrlForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCtaUrlForUserQuery, GetCtaUrlForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCtaUrlForUserQuery, GetCtaUrlForUserQueryVariables>(GetCtaUrlForUserDocument, options);
        }
export type GetCtaUrlForUserQueryHookResult = ReturnType<typeof useGetCtaUrlForUserQuery>;
export type GetCtaUrlForUserLazyQueryHookResult = ReturnType<typeof useGetCtaUrlForUserLazyQuery>;
export type GetCtaUrlForUserQueryResult = Apollo.QueryResult<GetCtaUrlForUserQuery, GetCtaUrlForUserQueryVariables>;
export const GetUserByTokenDocument = gql`
    query GetUserByToken($options: GetUserByTokenInput!) {
  getUserByToken(options: $options) {
    id
    roles
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetUserByTokenQuery__
 *
 * To run a query within a React component, call `useGetUserByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByTokenQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUserByTokenQuery(baseOptions: Apollo.QueryHookOptions<GetUserByTokenQuery, GetUserByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByTokenQuery, GetUserByTokenQueryVariables>(GetUserByTokenDocument, options);
      }
export function useGetUserByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByTokenQuery, GetUserByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByTokenQuery, GetUserByTokenQueryVariables>(GetUserByTokenDocument, options);
        }
export type GetUserByTokenQueryHookResult = ReturnType<typeof useGetUserByTokenQuery>;
export type GetUserByTokenLazyQueryHookResult = ReturnType<typeof useGetUserByTokenLazyQuery>;
export type GetUserByTokenQueryResult = Apollo.QueryResult<GetUserByTokenQuery, GetUserByTokenQueryVariables>;
