import * as Sentry from '@sentry/nextjs';

const isProduction = process.env.NODE_ENV === 'production';

const configure = (dsn: string) => {
  Sentry.init({
    attachStacktrace: true,
    dsn: isProduction ? dsn : undefined,
    environment: isProduction ? 'production' : 'development',
    tracesSampleRate: 1.0,
  });
};

export default configure;
